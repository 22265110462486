import { createAction } from "redux-actions";

// Profile
export const fetchProfileInfoPage = createAction("FETCH_PROFILE_INFO_PAGE");
export const fetchProfileInfo = createAction("FETCH_PROFILE_INFO");
export const fetchProfileInfoResponse = createAction(
  "FETCH_PROFILE_INFO_RESPONSE"
);
export const fetchProfileInfoError = createAction("FETCH_PROFILE_INFO_ERROR");
export const fetchUserProfile = createAction("FETCH_USER_PROFILE");

export const updateProfileInfo = createAction("UPDATE_PROFILE_INFO");
export const updateProfileInfoResponse = createAction(
  "UPDATE_PROFILE_INFO_RESPONSE"
);
export const updateProfileInfoError = createAction("UPDATE_PROFILE_INFO_ERROR");
export const isProfileInfoLoading = createAction("IS_PROFILE_INFO_LOADING");
export const updateProfileAgencyInfoResponse = createAction("UPDATE_PROFILE_AGENCY_INFO_RESPONSE");

// Billing

export const getCheckoutLink = createAction("GET_CHECKOUT_LINK");

export const getCheckoutLinkError = createAction("GET_CHECKOUT_LINK_ERROR");

export const isCardInfoLoading = createAction("CARD_INFO_LOADING");

export const isBillingInfoLoading = createAction("IS_BILLING_LOADING");

export const clearBillingInfo = createAction("CLEAR_BIILING_INFO");

export const clearErrors = createAction("CLEAR_ERRORS");

export const updateProfileLanguage = createAction("UPDATE_PROFILE_LANGUAGE");

export const createDevice = createAction("CREATE_DEVICE");
export const createDeviceResponse = createAction("CREATE_DEVICE_RESPONSE");
export const createDeviceError = createAction("CREATE_DEVICE_ERROR");

export const removeDevice = createAction("REMOVE_DEVICE");
export const removeDeviceResponse = createAction("REMOVE_DEVICE_RESPONSE");
export const removeDeviceError = createAction("REMOVE_DEVICE_ERROR");

export const confirmDevice = createAction("CONFIRM_DEVICE");
export const confirmDeviceResponse = createAction("CONFIRM_DEVICE_RESPONSE");
export const confirmDeviceError = createAction("CONFIRM_DEVICE_ERROR");

export const getStripeSessionInfo = createAction("GET_STRIPE_SESSION_INFO");

export const getCurrencies = createAction("GET_CURRENCIES");
export const setCurrencies = createAction("SET_CURRENCIES");
export const setCurrenciesLoading = createAction("SET_CURRENCIES_LOADING");
