import React, { ReactNode } from "react";
import fingerprintImg from "../../assets/images/landing/fingerprint.png";

interface BaseHeaderProps {
  leftContent: ReactNode;
  rightContent: ReactNode;
  bgColor?: string;
  showFingerPrint?: boolean
}

const BaseHeader: React.FC<BaseHeaderProps> = ({ leftContent, rightContent, bgColor = "yellow-200", showFingerPrint = true }) => (
  <div className={`md:h-80 bg-${bgColor} relative py-2`}>
    {
      showFingerPrint && (
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="container h-full relative">
            <div className="absolute right-0 -translate-y-1/2 fingerprint-container">
              <img
                src={fingerprintImg}
                alt="fingerprint background"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      )
    }

    <div className="container h-full relative">
      <div className="flex flex-col md:flex-row w-full h-full py-8">
        <div className="flex-1 md:self-end">
          {leftContent}
        </div>
        <div className="md:self-end">
          {rightContent}
        </div>
      </div>
    </div>
  </div>
);

export default BaseHeader;
