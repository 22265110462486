import React from "react";
import PropTypes from "prop-types";

export const Invite = ({ children, t }) => (
  <>
    {children}
    <td align="center" bgcolor="#656464" className="inner-td invite-td">
      <a href="{link}" className="invite-link" target="_blank">{t("messageTemplate.defaultInviteEmail.ctaBtnLabel")}</a>
    </td>

    <div className="invite-div">
      <a href="{link}">
        <span className="invite-span">{t("messageTemplate.defaultInviteEmail.link")}</span>
      </a>
    </div>

    <div className="invite-div divider"><br /></div>

    <h3 className="invite-title">
      <span className="invite-span"><strong>{t("messageTemplate.defaultInviteEmail.instructionsTitle")}</strong></span>
    </h3>
    <div className="invite-div"><br /></div>
    <div className="invite-div">
      <span className="invite-text">
        {t("messageTemplate.defaultInviteEmail.instructionsDescription")}
&nbsp;
      </span>
    </div>
    <div className="invite-div"><br /></div>
    <div className="invite-div">
      <span className="invite-text3">{t("messageTemplate.defaultInviteEmail.technicalQuestionTitle")}</span>
    </div>
    <div className="invite-div">
      <span className="invite-text4">
        {t("messageTemplate.defaultInviteEmail.visitUs")}
      </span>
      <a href="https://support.willo.video/collection/4-for-individuals">
        <span className="invite-text4 underline">
          {t("messageTemplate.defaultInviteEmail.visitPortal")}
        </span>
      </a>
      <span className="invite-text4">.</span>
    </div>
    <div className="invite-div"><br /></div>
    <div className="invite-div">
      <span className="invite-text invite-text5">
        {t("messageTemplate.defaultInviteEmail.reportAbuse")}
        <a href="https://support.willo.video/article/94-report-abuse" className="underline invite-text5">
          {t("messageTemplate.defaultInviteEmail.reportLink")}
        </a>
      </span>
    </div>
    <br />
    <div className="w-full">
      <a href="https://willo.video/" className="w-full flex flex-row gap-1 justify-center items-center hover:no-underline">
        {t("messageTemplate.defaultSuccessEmail.poweredBy")}
        <img className="max-width success-img2" border="0" width="86" alt="Willo Logo" data-proportionally-constrained="true" data-responsive="true" src="http://cdn.mcauto-images-production.sendgrid.net/ec244ba7289f378b/bd071cee-3049-4af3-a905-ff21cd2ea966/300x124.png" />
      </a>
    </div>
  </>
);

Invite.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired
};

export const Reminder = ({ children, t }) => (
  <>
    {children}
    <td align="center" bgcolor="#656464" className="inner-td reminder-td">
      <a href="{link}" className="reminder-link" target="_blank">{t("messageTemplate.defaultReminderEmail.title")}</a>
    </td>
    <div className="reminder-div"><br /></div>
    <div className="reminder-div divider" />

    <div className="reminder-div">
      <span className="reminder-span2">{t("messageTemplate.defaultReminderEmail.onBehalf")}</span>
      <a href="https://support.willo.video/article/94-report-abuse">
        <span className="reminder-span2">{t("messageTemplate.defaultReminderEmail.reportAbuse")}</span>
      </a>
      <br />
      <br />
      <div className="reminder-div2">
        <a href="https://willo.video/">
          {t("messageTemplate.defaultSuccessEmail.poweredBy")}
          <img className="max-width success-img2" border="0" width="86" alt="Willo Logo" data-proportionally-constrained="true" data-responsive="true" src="http://cdn.mcauto-images-production.sendgrid.net/ec244ba7289f378b/bd071cee-3049-4af3-a905-ff21cd2ea966/300x124.png" />
        </a>
      </div>
    </div>
  </>
);

Reminder.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired
};

export const Success = ({ children, t }) => (
  <div className="success-grid">
    <td className="success-td" valign="top" align="center">
      <img border="0" className="max-width success-img" width="285" alt="Interview Complete Header" data-proportionally-constrained="true" data-responsive="true" src="http://cdn.mcauto-images-production.sendgrid.net/ec244ba7289f378b/25e20f6d-8998-4093-9d27-2634b71c31f2/300x300.png" />
    </td>
    {children}

    <td align="center" bgcolor="#5A2AF2" className="inner-td success-td2">
      <a href="https://willo-talent.typeform.com/to/BEXujl" className="success-link" target="_blank" rel="noopener noreferrer">{t("messageTemplate.defaultSuccessEmail.rateMyExperience")}</a>
    </td>

    <div className="success-div">
      <span className="success-span">{t("messageTemplate.defaultSuccessEmail.completed")}</span>
    </div>
    <div className="success-div"><br /></div>
    <div className="success-div">
      <span className="success-span3 success-green-text">{t("messageTemplate.defaultSuccessEmail.emissionsStats")}</span>
      <span className="success-span4" role="img" aria-label="wave">👋</span>
    </div>

    <div className="success-div divider" />

    <td className="success-td3" valign="top" align="center">
      <a href="https://willo.video/">
        {t("messageTemplate.defaultSuccessEmail.poweredBy")}
        <img className="max-width success-img2" border="0" width="86" alt="Willo Logo" data-proportionally-constrained="true" data-responsive="true" src="http://cdn.mcauto-images-production.sendgrid.net/ec244ba7289f378b/bd071cee-3049-4af3-a905-ff21cd2ea966/300x124.png" />
      </a>
    </td>
  </div>
);

Success.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired
};
