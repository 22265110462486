/* eslint-disable import/no-cycle */
import { takeEvery, select, put } from "redux-saga/effects";

import {
  fetchNotifications,
  setNotificationsResponse,
  setPromoResponse,
  fetchNotificationsError,
  setQuotaExceedMute,
  setQuotaExceedMuteResponse,
  setShowTutorialMute,
  setShowTutorialMuteResponse
} from "store/modules/notifications/actions";
import { setIsNotificationsLoading, setLoadingScreen } from "store/modules/widgets/actions";
import { getCurrentUser, getCurrentUserId, getRoleOfCurrentUser } from "store/modules/users/selectors";
import { getToken } from "store/modules/auth/selectors";
import { ROLE_ADMIN } from "configs/user/rolesConfig";

import { isEmpty, isNull } from "lodash";
import { isCurrentUserCandidate } from "mixins/helperCandidate";
// eslint-disable-next-line import/no-cycle
import { getProfileInfo } from "store/modules/profile/selectors";
import { getRequestWithToken, putRequestWithToken } from "./api";

// eslint-disable-next-line consistent-return
export function* fetchNotificationsWorker({ payload: { key = "", refreshCompleteOnboarding } = {} }) {
  try {
    yield put(setLoadingScreen(true));
    yield put(setIsNotificationsLoading(true));
    const token = yield select(getToken);
    const profileInfo = yield select(getProfileInfo);

    if (isCurrentUserCandidate || isEmpty(token) || isNull(token) || !profileInfo?.is_verified) {
      return;
    }

    const role = yield select(getRoleOfCurrentUser);
    const isAdmin = ROLE_ADMIN === role;

    if (key && token) {
      const notifications = yield getRequestWithToken({
        url: `/api/agency/${key}/flags/`,
        token
      });

      const promo = yield getRequestWithToken({
        url: `/api/agency/${key}/promo/`,
        token
      });


      let setupData = {
        is_setup_completed: true
      };

      // find a way to wait for user info to get updated before calling this
      if (isAdmin) {
        setupData = yield select(getCurrentUser);
      }

      yield put(setPromoResponse({ promo: promo.data }));

      yield put(
        setNotificationsResponse({
          ...notifications.data,
          promo: promo.data,
          ...setupData.data,
          ...refreshCompleteOnboarding
            ? { completedOnboarding: setupData?.is_setup_completed } : null
        })
      );

      yield put(setLoadingScreen(false));
    }
  } catch (error) {
    console.log("[fetchJobsRequestError]", error);
    yield put(setLoadingScreen(false));
    yield put(fetchNotificationsError(error));
  } finally {
    yield put(setIsNotificationsLoading(false));
    yield put(setLoadingScreen(false));
  }
}

export function* setQuotaExceedMuteWorker({ payload: { key = "" } = {} }) {
  try {
    const token = yield select(getToken);

    if (key && token) {
      yield putRequestWithToken({
        url: `/api/agency/${key}/quota_exceed/mute/`,
        token
      });

      yield put(setQuotaExceedMuteResponse());
    }
  } catch (error) {
    console.log("[setQuotaExceedMuteWorker]", error);
  }
}

export function* setShowTutorialMuteWorker() {
  try {
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    if (agencyKey && token) {
      yield putRequestWithToken({
        url: `/api/agency/${agencyKey}/show_tutorial/mute/`,
        token
      });

      yield put(setShowTutorialMuteResponse());
    }
  } catch (error) {
    console.log("[setShowTutorialMuteWorker]", error);
  }
}

export function* createNotificationsWatcher() {
  yield takeEvery(fetchNotifications, fetchNotificationsWorker);
  yield takeEvery(setQuotaExceedMute, setQuotaExceedMuteWorker);
  yield takeEvery(setShowTutorialMute, setShowTutorialMuteWorker);
}

export default createNotificationsWatcher;
