/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { takeEvery, put, call, select } from "redux-saga/effects";

import { push } from "connected-react-router";

import {
  signIn,
  mfaSignin,
  signInWithLinkedIn,
  signInWithGoogle,
  signUp,
  confirmEmail,
  resetSendEmail,
  resetEmailIsSend,
  setResetEmail,
  resetPassword,
  signInResponse,
  signUpResponse,
  confirmEmailResponse,
  resetPasswordError,
  resetPasswordGetEmail,
  resetPasswordGetEmailResponse,
  resetPasswordGetEmailError,
  signInError,
  signUpError,
  confirmEmailError,
  isLoading,
  logOut,
  setSignInPayload,
  clearErrors,
  signInWithSSO
} from "store/modules/auth/actions";
import {
  setNotificationsResponse
} from "store/modules/notifications/actions";
import {
  fetchProfileInfoResponse,
  updateProfileLanguage
} from "store/modules/profile/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";
import { getLanguage } from "store/modules/language/selectors";

// TO-DO Rework cycle dependencies

/* eslint-disable-next-line */
import { setHasCurrentUserLoaded } from "store/modules/common/actions";
import { logErrors } from "mixins/helperLogging";
import { isEmpty } from "lodash";
import { REGISTRATION_STATUS } from "types/store/user.ts";
import { getToken } from "store/modules/auth/selectors";
import { history, persistor } from "../store";
/* eslint-disable-next-line */
import {
  getRequest,
  postRequest,
  getRequestWithToken,
  deleteRequestWithToken
} from "./api";
/* eslint-disable-next-line */
import { getCurrentUserWorker } from "./users";
/* eslint-disable-next-line */
import { recordEvent } from "utils/analytics";

export function* signInWorker({ payload = {} }) {
  try {
    yield put(isLoading(true));
    yield put(setLoadingScreen(true));
    yield put(setHasCurrentUserLoaded(true));

    const response = yield call(postRequest, {
      url: "/api/auth/login/",
      payload
    });

    if (response.status === 200) {
      if (response.data?.mfa_enabled && !response.data?.token) {
        const { recaptcha, ...rest } = payload;
        yield put(push("/multifa"));
        yield put(setSignInPayload(rest));
      } else if (response.data?.token) {
        const profile = yield getRequestWithToken({
          url: "/api/user/me/",
          token: response.data.token?.access
        });
        const language = yield select(getLanguage);
        const { status, data } = profile;

        yield put(
          signInResponse({
            token: response.data.token?.access,
            refreshToken: response.data.token?.refresh
          })
        );

        if (status === 200 && data && Object.keys(data).length > 0) {
          yield put(fetchProfileInfoResponse(data));
        }

        yield put(setSignInPayload(null));
        const currentUser = yield call(getCurrentUserWorker, { payload: {} });

        if (!["en", profile?.data?.language].includes(language) && language) {
          yield put(updateProfileLanguage(language));
        }

        if (currentUser?.registration_status !== REGISTRATION_STATUS.APPROVED) {
          yield call(history.push, "/account-verification");
          return yield put(setLoadingScreen(false));
        }

        if (!data.is_verified && currentUser?.registration_status ===
          REGISTRATION_STATUS.APPROVED) {
          yield call(history.push, "/email-verification");
          return yield put(setLoadingScreen(false));
        }

        if (data.is_verified && currentUser?.registration_status ===
          REGISTRATION_STATUS.APPROVED) {
          const notifications = yield getRequestWithToken({
            url: `/api/agency/${currentUser?.key}/flags/`,
            token: response.data.token?.access
          });


          // @TODO: Fix issue on duplicate api calls
          yield put(
            setNotificationsResponse({
              ...notifications.data
            })
          );
        }

        if (currentUser?.key) {
          // "Is Created" will be true if the account has just been created as part of sign-in
          if (response.data.is_created) {
            recordEvent("User", "SignIn", "", {
              userId: currentUser?.key
            });
          }
        }
      }
    }
  } catch (error) {
    let errorMessage = "signIn.errors.credentials";

    console.log("[signInWorkerRequestError]", error);
    logErrors({ error, context: { email: payload?.email, type: "all" } });

    const { data: {
      code = null,
      max_attempts: maxAttempts = 0,
      failed_attempts: failedAttempts = 0,
      detail = ""
    } = {} } = error?.response || {};

    if ((failedAttempts >= 4 || code === "inactive_user" ||
      failedAttempts >= maxAttempts) && code !== null) errorMessage = `signIn.errors.${code}`;

    const attempts = Number(maxAttempts - failedAttempts);

    if (error?.response?.status === 403 && !isEmpty(detail)) errorMessage = detail;

    if (
      error?.response?.data?.recaptcha?.[0]?.includes("Error verifying reCAPTCHA") ||
      (Array.isArray(error?.response?.data?.recaptcha))) {
      errorMessage = "signUp.errors.recaptchaVerification";
    }

    yield put(signInError({
      errorMessage,
      attempts
    }));


    yield put(setLoadingScreen(false));
    yield put(isLoading(false));
    if (
      error.response?.data?.non_field_errors?.includes("Invalid promo code.")
    ) {
      yield put(
        signUpError(
          "Sorry - this AppSumo code has already been used, if you think it is an error please contact support."
        )
      );
    }
  } finally {
    yield put(isLoading(false));
    yield put(setLoadingScreen(false));
  }
}

export function* mfaSigninWorker({ payload = "" }) {
  const {
    auth: { signin }
  } = yield select();

  if (signin) {
    yield put(
      signIn({
        ...signin,
        otp_token: payload
      })
    );
  } else {
    yield put(push("/sign-in"));
  }
}

export function* signUpWorker({ payload = {} }) {
  try {
    yield put(isLoading(true));
    yield put(setLoadingScreen(true));
    yield put(setHasCurrentUserLoaded(true));

    const response = yield call(postRequest, {
      url: "/api/auth/registration/",
      payload
    });

    yield put(
      signUpResponse({
        token: response.data.access,
        refreshToken: response.data.refresh
      })
    );

    if (response.status === 200) {
      const profile = yield getRequestWithToken({
        url: "/api/user/me/",
        token: response?.data?.access
      });

      const { status, data } = profile;

      if (status === 200 && data && Object.keys(data).length > 0) {
        yield put(fetchProfileInfoResponse(data));
      }

      // @TODO: there might not be enough time to save redux vs calling APIs
      const currentUser = yield call(getCurrentUserWorker, { payload: {} });
      const language = yield select(getLanguage);

      if (!["en", currentUser?.language].includes(language) && language) {
        yield put(updateProfileLanguage(language));
      }

      if (currentUser?.registration_status !== REGISTRATION_STATUS.APPROVED) {
        yield call(history.push, "/account-verification");
        return yield put(setLoadingScreen(false));
      }

      if (!data.is_verified && currentUser?.registration_status ===
        REGISTRATION_STATUS.APPROVED) {
        yield call(history.push, "/email-verification");
        return yield put(setLoadingScreen(false));
      }

      if (currentUser.key) {
        recordEvent("User", "SignUp", "", {
          userId: currentUser?.key
        });
      }
    }
  } catch (error) {
    let errorMessage = "signUp.errors.generic";

    if (error?.response?.data?.password) {
      errorMessage = error?.response?.data?.password?.join(" ");
    }

    if (error?.response?.data?.email && !error?.response?.data?.email?.includes("This email already register")) {
      errorMessage = error?.response?.data?.email?.join(" ");
    }

    if (error?.response?.data?.promo_code) {
      errorMessage = "signUp.errors.promoCode";
    }

    if (
      error?.response?.data?.recaptcha?.[0]?.includes("Error verifying reCAPTCHA") ||
      (Array.isArray(error?.response?.data?.recaptcha))) {
      errorMessage = "signUp.errors.recaptchaVerification";
    }

    if (error?.response?.status === 403) errorMessage = "signUp.errors.forbidden";
    yield put(signUpError(errorMessage));
    yield put(setLoadingScreen(false));
    yield put(isLoading(false));

    console.log("[requestError]", error);
  } finally {
    yield put(isLoading(false));
    yield put(setLoadingScreen(false));
  }
}

export function* confirmEmailWorker({ payload = {} }) {
  try {
    const { uid, token } = payload;
    const recruiterToken = yield select(getToken);

    yield put(isLoading(true));

    const response = yield getRequest({
      url: `/api/auth/activate/${uid}/${token}/`
    });

    if (response.status === 200) {
      yield put(
        confirmEmailResponse({
          email: response.data.email,
          message: "success.emailConfirmed"
        })
      );
      // Refetch recruiter data after verification
      if (recruiterToken) {
        const { status, data } = yield getRequestWithToken({
          url: "/api/user/me/",
          token: recruiterToken
        });

        if (status === 200 && data && Object.keys(data).length > 0) {
          yield put(fetchProfileInfoResponse(data));
        }
      } else {
        yield put(push("/sign-in"));
      }
    }
  } catch (error) {
    yield put(
      confirmEmailError(
        "We have been unable to send a confirmation email - please try again or contact us if the problem persists."
      )
    );
    yield put(push("/sign-up"));
    console.log("[confrimEmailError]", error);
  } finally {
    yield put(isLoading(false));
  }
}

export function* resetPasswordGetEmailWorker({ payload: { token = "" } = {} }) {
  try {
    yield put(isLoading(true));
    const response = yield getRequest({
      url: `/api/auth/reset-password/${token}`
    });

    if (response.status === 200) {
      yield put(resetPasswordGetEmailResponse(response.data.email));
      yield put(resetEmailIsSend(true));
    }
  } catch (error) {
    yield put(resetPasswordGetEmailError("errors.validResetPassword"));
    console.log("[resetSendEmailRequestError]", error);
  } finally {
    yield put(isLoading(false));
  }
}

export function* resetSendEmailWorker({ payload = {} }) {
  try {
    yield put(clearErrors());
    const { email } = payload;

    yield put(isLoading(true));
    yield postRequest({ url: "/api/auth/reset-password/", payload });
    yield put(setResetEmail(email));
    yield put(resetEmailIsSend(true));
  } catch (error) {
    yield put(resetPasswordError("errors.validResetPassword"));
    console.log("[resetSendEmailRequestError]", error);
  } finally {
    yield put(isLoading(false));
  }
}

export function* resetPasswordWorker({ payload = {} }) {
  try {
    const { url } = payload;

    yield put(isLoading(true));
    yield postRequest({ url: `/api/auth/reset-password/${url}`, payload });
    yield put(push("/sign-in"));
  } catch (error) {
    yield put(
      resetPasswordError("Could not reset password, please resend email")
    );
    console.log("[resetPassworRequestError]", error);
  } finally {
    yield put(isLoading(false));
  }
}

export function* logOutWorker({ payload }) {
  try {
    if (payload?.token && payload?.refreshToken) {
      yield deleteRequestWithToken({
        url: "/api/auth/logout/",
        token: payload.token,
        payload: {
          refresh: payload.refreshToken
        }
      });
    }

    yield put(push("/sign-in"));
    yield call(() => Promise.resolve(persistor.flush()));
  } catch (error) {
    yield call(() => Promise.resolve(persistor.flush()));
  }
}

export function* authWatcher() {
  yield takeEvery(signIn, signInWorker);
  yield takeEvery(signInWithLinkedIn, signInWorker);
  yield takeEvery(signInWithGoogle, signInWorker);
  yield takeEvery(signInWithSSO, signInWorker);
  yield takeEvery(signUp, signUpWorker);
  yield takeEvery(confirmEmail, confirmEmailWorker);
  yield takeEvery(resetSendEmail, resetSendEmailWorker);
  yield takeEvery(resetPasswordGetEmail, resetPasswordGetEmailWorker);
  yield takeEvery(resetPassword, resetPasswordWorker);
  yield takeEvery(logOut, logOutWorker);
  yield takeEvery(mfaSignin, mfaSigninWorker);
}

export default authWatcher;
