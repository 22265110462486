/* eslint-disable no-restricted-globals */

import { isNumber } from "lodash";

const getValidVolume = val => {
  if (isNumber(val)) {
    return val;
  }
  return 1;
};

/**
 * Converts seconds to a formatted minutes and seconds string with proper pluralization
 * @param {number} seconds - The number of seconds to convert
 * @returns {string} Formatted string like "1 min" or "2 mins"
 */
export const secondsToMinutes = seconds => {
  if (!seconds || !isNumber(seconds)) return null;

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes === 0) {
    return `${remainingSeconds} ${remainingSeconds === 1 ? "second" : "seconds"}`;
  }

  return `${minutes} ${minutes === 1 ? "min" : "mins"}`;
};

export default getValidVolume;
