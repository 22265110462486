import rootSaga from "sagas";
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from "history";

import configureStore from "./configureStore";
import reducers from "./reducers";

const createStore = history => {
  try {
    const rootReducer = reducers(history);
    return configureStore(rootReducer, rootSaga, history);
  } catch (error) {
    console.error("Store initialization error:", error);
    return {
      store: {
        getState: () => ({}),
        dispatch: () => null
      },
      persistor: {
        persist: () => null
      }
    };
  }
};

const history = createBrowserHistory();
const { store, persistor } = createStore(history);

export { history, store, persistor };
