/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";

import Header from "containers/Common/HeaderContainer";
import Send from "components/Candidates/Progress/Send";
import Received from "components/Candidates/Progress/Received";
import List from "components/Candidates/List";
import UsersList from "components/Candidates/UsersList";
import Breadcrumbs from "components/Breadcrumbs";
import Switcher from "components/Switcher";
import HeaderJob from "components/Common/Header/Job";
import CandidateSearch from "components/Forms/CandidateSearch";
import PageWrapper from "hoc/PageWrapper";
import { jobsBreadcrumbs } from "mixins/dynamicRoutes";
import { DATE_FORMATS } from "configs/dates";
import { JOB_STATUSES } from "configs/jobs/constants";

import "./styles.scss";
import { Edit, EyeWithFill } from "mixins/svgIcons";
import TooltipSuccess from "components/Common/Tooltip/Success";
import { uniqBy } from "lodash";
import { setExcludedCandidates, setSelectAll, setTotalSelectedCount } from "store/modules/jobs/actions";
import { store } from "store";
import { getJobsMaximum } from "store/modules/users/selectors";
import { connect } from "react-redux";
import { getToken } from "store/modules/auth/selectors";
import PersonaButtonWrapper from "components/PersonaVerification/PersonaButtonWrapper.tsx";
import { Share } from "../Candidate/svgIcons";
import ConnectDropdown from "./ConnectDropdown/ConnectDropdown";

const Overview = ({
  t,
  isAdmin,
  isCandidatesStatsLoading,
  isNextCandidatesLoading,
  isJobStatsLoading,
  isSelectedCandidatesLoading,
  match: {
    params: { jobId = "" }
  },
  jobCurrent,
  profileInfo,
  interviewers,
  interviewersProgress,
  interviewersFilter,
  fetchCurrentJob,
  jobCompany,
  fetchCandidatesList,
  fetchNextCandidatesList,
  fetchJobUsersList,
  fetchNextJobUsersList,
  isAbiltyСreate,
  deleteJob,
  restoreJob,
  clearStatuses,
  currentUser,
  updateCandidatesList,
  downloadCandidatesList,
  shareCandidatesListPublic,
  bulkShowcaseLink,
  bulkKey,
  clearSharableLink,
  setAvailableAnswerBulk,
  setModal,
  selectedCandidates,
  setSelectedCandidates,
  setSelectedCandidatesByStatus,
  setSelectedCandidatesLoading,
  jobUsers,
  usersCanSeeCount,
  jobUsersModal,
  isUsersLoading,
  isNextUsersLoading,
  updateJobUsersList,
  inviteUsers,
  inviteLoading,
  inviteError,
  addNewUsersError,
  agencyKey,
  getJobAtsDetails,
  atsDetails,
  resetJobAtsDetails,
  onboarding,
  maximumJobs
}) => {
  const isMaximumJobs = maximumJobs <= onboarding?.agency_jobs_amount;
  const statusRef = useRef(null);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [rank, setRank] = useState("");


  const questions = (jobCurrent?.questions || []).sort(
    (a, b) => a.order - b.order
  );

  const [selectedStatus, setSelectedStatus] = useState("");

  const changeSelectedCandidates = (items, isDeleted = false) => {
    if (isDeleted) {
      const newSelectedCandidates = selectedCandidates.filter(item =>
        !items.map(a => a.id).includes(item.id));
      store.dispatch(setExcludedCandidates(items));
      setSelectedCandidates(newSelectedCandidates);
      store.dispatch(setTotalSelectedCount({
        selectedItems: newSelectedCandidates
      }));
    } else {
      const newSelectedCandidates = uniqBy([...new Set([...selectedCandidates, ...items])], "id");
      setSelectedCandidates(newSelectedCandidates);
      store.dispatch(setTotalSelectedCount({ selectedItems: newSelectedCandidates }));
    }
  };

  useEffect(() => {
    fetchCurrentJob(jobId);
    fetchJobUsersList({ jobId, query: null });
    setSelectedCandidates([]);
    store.dispatch(setSelectAll({
      accepted: false,
      rejected: false,
      invited: false,
      received: false
    }));
    store.dispatch(setExcludedCandidates([]));
    setSelectedCandidatesLoading(false);
  }, [jobId]);

  useEffect(() => {
    let isMounted = true;
    const hasJobId = jobId !== "" && typeof jobId !== "undefined";
    const hasAgencyKey = agencyKey !== "" && typeof agencyKey !== "undefined";

    if (hasJobId && hasAgencyKey && isMounted) {
      isMounted = false;
      getJobAtsDetails({
        jobId,
        agencyKey,
        params: {
          interview_key: jobId
        }
      });
    }

    return () => {
      isMounted = true;
      resetJobAtsDetails();
    };
  }, [jobId, agencyKey]);


  useEffect(() => {
    window.addEventListener("beforeunload", resetJobAtsDetails);
    return () => {
      window.removeEventListener("beforeunload", resetJobAtsDetails);
    };
  }, []);

  const handleOn = () => deleteJob({ jobId });
  const handleOff = () => restoreJob(jobId);
  const handleBillingModal = () => (isAbiltyСreate ? false : window.open("/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_response", "_blank"));
  const hasAbilityEditJob =
    isAdmin || profileInfo.email === jobCurrent.created_by_email;


  return (
    <PageWrapper header={<Header />} prefixPage="overview-job">
      <Container>
        <Row>
          <Col md={12}>
            <Breadcrumbs
              pathList={jobsBreadcrumbs(t, jobCurrent.title, jobId)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={10}>
            <HeaderJob
              isShowCreatedBy={!hasAbilityEditJob}
              createdBy={jobCurrent.created_by_email || ""}
              job={jobCurrent}
              company={jobCompany}
            />
          </Col>
          <Col md={2}>
            {hasAbilityEditJob && (
              <>
                <p className="candidate__button-switcher">
                  <span className="candidate__button-switcher-text n-font-small n-grey-100">
                    {t("job.table.live")}
                  </span>
                  <span
                    className="candidate__button-switcher-main"
                    ref={statusRef}
                    onMouseEnter={() => setIsShowTooltip(jobCurrent.status === JOB_STATUSES.LIVE)}
                    onMouseLeave={() => setIsShowTooltip(false)}
                  >
                    <Switcher
                      checked={jobCurrent.status === JOB_STATUSES.LIVE}
                      handleOn={handleOn}
                      handleOff={handleOff}
                      disabled={isMaximumJobs && jobCurrent.status !== JOB_STATUSES.LIVE}
                    />
                  </span>
                </p>
                <TooltipSuccess
                  position="bottom"
                  // eslint-disable-next-line no-nested-ternary
                  message={isMaximumJobs
                    ? maximumJobs === 1 ? t("plans.maxJob", { value: maximumJobs }) : t("plans.maximumJobs", { value: maximumJobs })
                    : t("job.overview.liveStatusTooltip")}
                  target={statusRef.current}
                  show={isShowTooltip}
                />
              </>
            )}
          </Col>
        </Row>

        <div className="cta-wrapper">
          <div className="cta-buttons">
            {jobCurrent.status === JOB_STATUSES.LIVE ? (
              <PersonaButtonWrapper>
                <Link
                  className="main-link main-link__without-decoration"
                  to={`/jobs/invite/${jobId}`}
                >
                  <button
                    className="n-button__medium n-md n-bg-purple-100 n-white"
                    type="button"
                  >
                    <Share fill="#FFFFFF" height="18px" />
                    <span>{t("button.invite")}</span>
                  </button>
                </Link>
              </PersonaButtonWrapper>
            ) : null}

            {isAdmin || hasAbilityEditJob ? (
              <Link
                className="main-link main-link__without-decoration"
                to={`/jobs/edit/${jobId}`}
                onClick={clearStatuses}
              >
                <button
                  className="n-button__medium-border n-md n-border-grey-100 n-grey flex flex-row items-center"
                  type="button"
                >
                  <Edit fill="#595959" height="14px" />
                  <span>{t("button.edit")}</span>
                </button>
              </Link>
            ) : null}

            {jobCurrent.status === JOB_STATUSES.LIVE ? (

              <PersonaButtonWrapper>
                <a href={jobCurrent.invite_link} target="_blank" rel="noopener noreferrer">
                  <button
                    className="n-button__medium-border n-md n-border-grey-100 n-grey"
                    type="button"
                  >
                    <EyeWithFill width="18px" />
                    <span>{t("button.preview")}</span>
                  </button>
                </a>
              </PersonaButtonWrapper>
            ) : null}

            <ConnectDropdown
              atsDetails={atsDetails}
              agencyKey={agencyKey}
              jobId={jobId}
            />
          </div>

          <UsersList
            usersCanSeeCount={usersCanSeeCount}
            jobCurrent={jobCurrent}
            companyName={jobCompany?.name}
            jobUsers={jobUsers}
            jobUsersModal={jobUsersModal}
            fetchJobUsersList={fetchJobUsersList}
            fetchNextJobUsersList={fetchNextJobUsersList}
            isUsersLoading={isUsersLoading}
            isNextUsersLoading={isNextUsersLoading}
            updateJobUsersList={updateJobUsersList}
            hasAbilityEdit={hasAbilityEditJob}
            currentUserKey={currentUser.key}
            inviteUsers={inviteUsers}
            inviteLoading={inviteLoading}
            inviteError={inviteError}
            addNewUsersError={addNewUsersError}
          />
        </div>

        <Row>
          <Col md={6}>
            <Send
              isLoading={isJobStatsLoading}
              invited={interviewersProgress.invited}
              received={interviewersProgress.to_review}
              accepted={interviewersProgress.accepted}
              rejected={interviewersProgress.rejected}
            />
          </Col>

          <Col md={4}>
            <Received
              invited={jobCurrent.applications_cnt}
              interviews={jobCurrent.candidates_cnt}
              start={moment(jobCurrent.created_at).format(
                DATE_FORMATS.SPACE_SEPARATOR_LONG_MONTH
              )}
              end={
                jobCurrent.deadline
                  ? moment(jobCurrent.deadline).format(
                    DATE_FORMATS.SPACE_SEPARATOR_LONG_MONTH
                  )
                  : t("status.noEndDate")
              }
            />
          </Col>
        </Row>

        <div className="overview_space" />
        <CandidateSearch
          updateCandidatesList={updateCandidatesList}
          fetchCandidatesList={fetchCandidatesList}
          downloadCandidatesList={downloadCandidatesList}
          shareCandidatesListPublic={shareCandidatesListPublic}
          jobId={jobId}
          selected={selectedCandidates}
          setSelected={setSelectedCandidates}
          bulkShowcaseLink={bulkShowcaseLink}
          bulkKey={bulkKey}
          clearSharableLink={clearSharableLink}
          questions={questions}
          setAvailableAnswerBulk={setAvailableAnswerBulk}
          setModal={setModal}
          isSelectedCandidatesLoading={isSelectedCandidatesLoading}
          setRank={setRank}
          rank={rank}
          clearSelectedStatus={() => {
            setSelectedStatus("");
          }}
        />

        <List
          isLoading={isCandidatesStatsLoading}
          isNextLoading={isNextCandidatesLoading}
          job={jobCurrent}
          interviewers={interviewers}
          interviewersAmount={interviewersFilter}
          handleBillingModal={handleBillingModal}
          currentUser={currentUser}
          changeSelected={changeSelectedCandidates}
          setSelectedCandidatesByStatus={setSelectedCandidatesByStatus}
          selected={selectedCandidates}
          className="overview"
          fetchItems={fetchNextCandidatesList}
          rank={rank}
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
        />
      </Container>
    </PageWrapper>
  );
};

Overview.defaultProps = {
  isCandidatesStatsLoading: undefined,
  isNextCandidatesLoading: undefined,
  isJobStatsLoading: undefined,
  isSelectedCandidatesLoading: undefined,
  bulkShowcaseLink: undefined,
  bulkKey: undefined,
  isNextUsersLoading: undefined,
  inviteError: undefined,
  isUsersLoading: undefined,
  agencyKey: undefined,
  getJobAtsDetails: undefined,
  atsDetails: undefined,
  resetJobAtsDetails: undefined,
  currentUser: undefined,
  onboarding: undefined
};

Overview.propTypes = {
  t: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCandidatesStatsLoading: PropTypes.bool,
  isNextCandidatesLoading: PropTypes.bool,
  isJobStatsLoading: PropTypes.bool,
  isSelectedCandidatesLoading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string
    })
  }).isRequired,
  profileInfo: PropTypes.shape({
    email: PropTypes.string
  }).isRequired,
  jobCurrent: PropTypes.shape({
    title: PropTypes.string,
    deadline: PropTypes.string,
    created_by_email: PropTypes.string,
    candidates_cnt: PropTypes.number,
    applications_cnt: PropTypes.number,
    created_at: PropTypes.string,
    status: PropTypes.string,
    questions: PropTypes.array,
    company_name: PropTypes.string,
    company_location: PropTypes.string,
    invite_link: PropTypes.string
  }).isRequired,
  interviewers: PropTypes.shape({
    invited: PropTypes.arrayOf(PropTypes.shape({})),
    received: PropTypes.arrayOf(PropTypes.shape({})),
    accepted: PropTypes.arrayOf(PropTypes.shape({})),
    rejected: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  jobCompany: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string
  }).isRequired,
  interviewersProgress: PropTypes.shape({
    invited: PropTypes.number,
    to_review: PropTypes.number,
    accepted: PropTypes.number,
    rejected: PropTypes.number
  }).isRequired,
  interviewersFilter: PropTypes.shape({
    invited: PropTypes.number,
    to_review: PropTypes.number,
    accepted: PropTypes.number,
    rejected: PropTypes.number
  }).isRequired,
  fetchCurrentJob: PropTypes.func.isRequired,
  fetchCandidatesList: PropTypes.func.isRequired,
  isAbiltyСreate: PropTypes.bool.isRequired,
  deleteJob: PropTypes.func.isRequired,
  restoreJob: PropTypes.func.isRequired,
  clearStatuses: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    key: PropTypes.string
  }),
  updateCandidatesList: PropTypes.func.isRequired,
  downloadCandidatesList: PropTypes.func.isRequired,
  shareCandidatesListPublic: PropTypes.func.isRequired,
  bulkShowcaseLink: PropTypes.string,
  bulkKey: PropTypes.string,
  clearSharableLink: PropTypes.func.isRequired,
  setAvailableAnswerBulk: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  fetchNextCandidatesList: PropTypes.func.isRequired,
  selectedCandidates: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedCandidates: PropTypes.func.isRequired,
  setSelectedCandidatesByStatus: PropTypes.func.isRequired,
  setSelectedCandidatesLoading: PropTypes.func.isRequired,
  fetchJobUsersList: PropTypes.func.isRequired,
  fetchNextJobUsersList: PropTypes.func.isRequired,
  jobUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  usersCanSeeCount: PropTypes.number.isRequired,
  jobUsersModal: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUsersLoading: PropTypes.bool,
  isNextUsersLoading: PropTypes.bool,
  updateJobUsersList: PropTypes.func.isRequired,
  inviteUsers: PropTypes.func.isRequired,
  inviteLoading: PropTypes.bool.isRequired,
  inviteError: PropTypes.string,
  addNewUsersError: PropTypes.func.isRequired,
  agencyKey: PropTypes.string,
  getJobAtsDetails: PropTypes.func,
  atsDetails: PropTypes.shape({}),
  resetJobAtsDetails: PropTypes.func,
  onboarding: PropTypes.shape({
    agency_jobs_amount: PropTypes.number
  }),
  maximumJobs: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  maximumJobs: getJobsMaximum(state),
  onboarding: state.dashboard?.onboarding,
  token: getToken(state)
});

export default connect(mapStateToProps)(withTranslation()(Overview));
