import React from "react";

const CollapsibleComponent = ({
  show,
  children
}: {
  show: boolean;
  children: React.ReactNode;
}) => (
  <div
    style={{
      height: show ? "auto" : 0,
      overflow: "hidden",
      transition: "height 300ms ease-in-out, opacity 300ms ease-in-out",
      opacity: show ? 1 : 0
    }}
  >
    {children}
  </div>
);

export default CollapsibleComponent;
