/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Container, Col, Row } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import Switch from "rc-switch";

import PageWrapper from "hoc/PageWrapper";
import withLoaderScreen from "hoc/withLoaderScreen";
import ProfileCard from "components/Common/ProfileCard";
import ProgressPanel from "components/Common/Progress/Panel";
import PlanUpgrade from "components/PlanUpgrade";
import ManualInvoice from "components/Billing/ManualInvoce";
import Header from "containers/Common/HeaderContainer";
import Billing from "containers/Billing/BillingContainer";
import { Agency, Record, Info, Person, Edit, Sms } from "mixins/svgIcons";
import { resolvePayment, resolveCurrency } from "mixins/helperForPayment";
import {
  setProfileInfo,
  handlePaymentDuration,
  getTrialPeriodEnd
} from "mixins/helperProfile";
import { PLANS_NAMES } from "configs/plans";
import { ROLE_ADMIN } from "configs/user/rolesConfig";
import { DATE_FORMATS } from "configs/dates";
import TooltipInfo from "components/Common/Tooltip/Info";

import "./styles.scss";
import { store } from "store";
import { fetchStripePortalUrl } from "store/modules/users/actions";
import { VerifiedUserIcon } from "components/Answer/List/svgIcons";
import useVerificationStore, { VerificationStatus } from "store/verificationStore.ts";
import CustomTooltip from "components/Common/CustomTooltip/CustomTooltip.tsx";
import PersonaButtonWrapper from "components/PersonaVerification/PersonaButtonWrapper.tsx";

const Profile = ({
  t,
  profileInfo,
  profileInfo: {
    full_name: fullName = ""
  },
  currentUser: {
    is_pro_account: isProAccount = false,
    responses_quota: monthlyQuota = 0,
    sms_quota: smsMonthlyQuota = 0,
    responses_quota_used: currentInterviewersAmount = 0,
    sms_quota_used: smsMonthlyQuotaUsed = 0,
    billing_period_start: billingPeriodStart = "",
    billing_period_end: billingPeriodEnd = "",
    next_charging_date: nextChargingDate = "",
    is_owner: isOwner = false,
    integration_key: integrationKey,
    role,
    plan_name: planName,
    users_amount: usersAmount = 1,
    name: currentUserName,
    plan_currency: planCurrency = "usd",
    plan_price_per_user: planPricePerUser = 0,
    plan_price_per_extra_response: planPricePerInterview = 0,
    plan_price_per_extra_sms: planPricePerExtraSms = 0,
    primary_subscription_id: primarySubscriptionId,
    sms_enabled: smsEnabled,
    card: {
      last4 = "",
      exp_month: expMonth = null,
      exp_year: expYear = null
    } = {},
    customer_billing_info: billingInfo
  } = {},
  currentUser,
  updateProfileInfo,
  clearErrors,
  history,
  notifications: {
    is_trial_period: isTrialPeriod = false,
    trial_period_end: trialPeriodEnd = null
  },
  updateAgency,
  errorCheckout
}) => {
  const [profileListInfo, setControlsProfileListInfo] = useState([]);
  const [controls, setControls] = useState({ isEditFormDisplay: false });
  const [isUpgradePlan, setIsUpgradePlan] = useState(false);
  const pricePerUser = planPricePerUser * 100;
  const pricePerInterview = planPricePerInterview * 100;
  const isFree = planName === PLANS_NAMES.freeForever;
  const { verificationStatus } = useVerificationStore();

  useEffect(() => {
    clearErrors();
    store.dispatch(fetchStripePortalUrl({
      id: primarySubscriptionId,
      type: "fetch_stripe_portal_url",
      redirect: false
    }));
  }, []);

  useEffect(() => {
    if (profileInfo) {
      setControlsProfileListInfo(
        setProfileInfo({
          ...profileInfo,
          agencyName: currentUserName,
          integration_key: integrationKey
        })
      );
    }
  }, [profileInfo, currentUserName, integrationKey]);

  useEffect(() => {
    if (isFree || isTrialPeriod) {
      setIsUpgradePlan(true);
    } else {
      setIsUpgradePlan(false);
    }
  }, [planName]);

  const toggleEditProfile = () => {
    setControls({ isEditFormDisplay: !controls.isEditFormDisplay });
  };

  const { fullDuration } = handlePaymentDuration(billingPeriodStart, billingPeriodEnd);

  const payment = resolvePayment({
    planInfo: { pricePerUser, pricePerInterview, planCurrency },
    usersCount: usersAmount,
    interviewsQuota: monthlyQuota,
    interviewsCount: currentInterviewersAmount
  });

  const isAdmin = role === ROLE_ADMIN && !isOwner;
  const isUserHasCard = Boolean(last4) && Boolean(expMonth) && Boolean(expYear);

  const handleUpgrade = () =>
    history.push(
      "/plans?utm_source=internal&utm_medium=user&utm_campaign=profile_upgrade"
    );
  const withVat = useMemo(
    () => (billingInfo?.country === "GB" ? " +VAT" : ""),
    [billingInfo]
  );

  const trialDaysCount = useMemo(() => getTrialPeriodEnd(trialPeriodEnd, t), [
    trialPeriodEnd,
    t
  ]);

  const handleChangeSmsEnabled = value => updateAgency({ sms_enabled: value });

  return (
    <PageWrapper header={<Header />}>
      <Container className="profile">
        <Row className="justify-content-md-center">
          <Col md={4}>
            <ProfileCard>
              <ProfileCard.Header>
                <button
                  type="button"
                  className="button__edit-profile"
                  onClick={toggleEditProfile}
                >
                  <Edit />
                </button>

                {controls.isEditFormDisplay ? (
                  <ProfileCard.EditForm
                    updateProfileInfo={updateProfileInfo}
                    profileInfo={profileInfo}
                    hideEditProfile={toggleEditProfile}
                    isOwner={isOwner}
                  />
                ) : (
                  <>
                    <ProfileCard.Title>{fullName}</ProfileCard.Title>
                    <ProfileCard.List items={profileListInfo} t={t} />
                  </>
                )}
              </ProfileCard.Header>
            </ProfileCard>
          </Col>

          <Col md={{ span: 5, offset: 1 }}>
            <header className="profile__header">
              <div>
                <h2 className="flex flex-row items-end">
                  <Agency />
                  {currentUserName}
                  {
                    [VerificationStatus.VERIFIED,
                      VerificationStatus.VERIFIED_MANUAL,
                      VerificationStatus.PENDING_VERIFICATION
                    ].includes(verificationStatus) && (
                      <div className="ml-1 mt-1">
                        <CustomTooltip
                          content={t("verification.success")}
                          position="bottom"
                        >
                          <VerifiedUserIcon size={24} color="#59cc4c" />
                        </CustomTooltip>
                      </div>
                    )
                  }
                </h2>
                {isTrialPeriod ? (
                  <span>
                    {t("notifications.trial_end", { count: trialDaysCount, plan: planName })}
                  </span>
                ) : (
                  <span>
                    {t("profile.current", { plan: isFree ? t("profile.freeForever") : planName })}
                  </span>
                )}
              </div>

              {
                (isOwner || isAdmin)
                && !isTrialPeriod && !isProAccount && !isFree && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    className="n-button__medium-border n-purple-100 profile__change-plan"
                    onClick={() => {
                      store.dispatch(fetchStripePortalUrl({
                        primarySubscriptionId,
                        redirect: true,
                        type: "upgrade_plan"
                      }));
                    }}
                  >
                    {t("link.changePlan")}
                  </Link>
                )
              }
            </header>


            {
              errorCheckout ? (
                <p
                  className="input-error-message"
                  style={{
                    position: "relative",
                    left: 0,
                    paddingBottom: 10
                  }}
                >
                  {errorCheckout}

                </p>
              ) : null
            }

            <ProgressPanel postfix="records border-bottom">
              <p className="profile__text n-grey-60 n-font-light">{`${t("profile.period")} ${fullDuration ?? ""}`}</p>
              <p className="profile__title-progress n-grey-60">{`${t("profile.responses")}:`}</p>

              <ProgressPanel.LeftLabel
                label={(
                  <Record
                    fill={
                      currentInterviewersAmount / monthlyQuota >= 0.9
                        ? "#DC3545"
                        : undefined
                    }
                  />
                )}
                infoLabel={(
                  <TooltipInfo
                    prefix="profile__info"
                    message={t("profile.interviewsInfo", { currency: `${resolveCurrency(planCurrency)}${planPricePerInterview}` })}
                    position="bottom"
                    element={<Info width="15px" height="15px" />}
                  />
                )}
              >
                <ProgressPanel.MovingLabels>
                  <ProgressPanel.MovingLabelLeft
                    position="top"
                    currentValue={currentInterviewersAmount}
                    totalValue={monthlyQuota}
                  />
                  <ProgressPanel.MovingLabelRight
                    position="bottom"
                    currentValue={currentInterviewersAmount}
                    totalValue={monthlyQuota}
                    style={{ right: 60 }}
                  />
                </ProgressPanel.MovingLabels>

                <ProgressPanel.ProgressBarGraph
                  currentValue={currentInterviewersAmount}
                  totalValue={monthlyQuota}
                />
              </ProgressPanel.LeftLabel>
              {(isOwner || isAdmin) && (
                <div className={`profile__sms-switcher profile__title-progress ${smsEnabled ? "" : "sms-disabled"}`}>
                  <p className="profile__sms-switcher-text">
                    <span>{`${t("profile.smsInvites")}:`}</span>
                  </p>
                  {isFree ? (
                    <PersonaButtonWrapper>
                      <Link
                        to="/plans?utm_source=internal&utm_medium=user&utm_campaign=trial_complete"
                      >
                        <i className="fas gold fa-lock n-icon__medium right" />
                      </Link>
                    </PersonaButtonWrapper>
                  ) : (
                    <Switch
                      name="sms"
                      onChange={handleChangeSmsEnabled}
                      checked={smsEnabled}
                    />
                  )}
                </div>
              )}

              {smsEnabled && (
                <ProgressPanel.LeftLabel
                  label={(
                    <Sms
                      fill={
                        smsMonthlyQuotaUsed / smsMonthlyQuota >= 0.9
                          ? "#DC3545"
                          : undefined
                      }
                    />
                  )}
                  infoLabel={(
                    <TooltipInfo
                      prefix="profile__info"
                      message={t("profile.smsInfo", { currency: `${resolveCurrency(planCurrency)}${planPricePerExtraSms}` })}
                      position="bottom"
                      element={<Info width="15px" height="15px" />}
                    />
                  )}
                >
                  <ProgressPanel.MovingLabels>
                    <ProgressPanel.MovingLabelLeft
                      position="top"
                      currentValue={smsMonthlyQuotaUsed}
                      totalValue={smsMonthlyQuota}
                    />
                    <ProgressPanel.MovingLabelRight
                      position="bottom"
                      currentValue={smsMonthlyQuotaUsed}
                      totalValue={smsMonthlyQuota}
                      style={{ right: 63 }}
                    />
                  </ProgressPanel.MovingLabels>

                  <ProgressPanel.ProgressBarGraph
                    currentValue={smsMonthlyQuotaUsed}
                    totalValue={smsMonthlyQuota}
                  />
                </ProgressPanel.LeftLabel>
              )}
              <ProgressPanel.Footer>
                <p className="profile__text n-grey-60 n-font-light flex flex-row items-center">
                  <span title="Title for Interviews">
                    <Info tip="test" />
                  </span>
                  {t("profile.unusedResponsesInfo")}
                </p>
              </ProgressPanel.Footer>
            </ProgressPanel>

            {(isOwner || isAdmin) &&
              !isFree && (
              <ProgressPanel postfix="border-bottom">
                <ProgressPanel.TotalTitle>
                  {t("profile.totalUsers")}
                </ProgressPanel.TotalTitle>
                <Link
                  to="/users"
                >
                  <ProgressPanel.LeftLabel label={<Person />}>
                    <span className="profile__number">{usersAmount}</span>
                  </ProgressPanel.LeftLabel>
                </Link>

                {!isProAccount && (
                  <ProgressPanel.Footer>
                    {isOwner && (
                      <>
                        <p>
                          {t("profile.yourPlan", {
                            date: `${t(`month.${moment(nextChargingDate).format(DATE_FORMATS.MONTH)}`)} ${moment(nextChargingDate).format(DATE_FORMATS.DAY_YEAR)}`
                          })}
                        </p>
                        <p>
                          {t("profile.extraInterviews", {
                            currency: `${payment.extra} ${payment.paymentForInterviews ? withVat : ""}`
                          })}
                        </p>
                      </>
                    )}
                  </ProgressPanel.Footer>
                )}
              </ProgressPanel>
            )}

            {(isOwner || isAdmin) &&
              (isUpgradePlan ? (
                <PlanUpgrade isProAccount={isProAccount} />
              ) : (
                <>
                  {isProAccount && (
                    <div className="card__block">
                      <h2 className="card__block-title">
                        {t("billing.information")}
                      </h2>
                      <ManualInvoice />
                    </div>
                  )}

                  {isOwner && !isProAccount && (
                    <div className="card__block">
                      <h2 className="card__block-title">
                        {t("billing.information")}
                      </h2>
                      <>
                        {isUserHasCard && (
                          <div className="card__link-update">
                            <Link
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                store.dispatch(fetchStripePortalUrl({
                                  primarySubscriptionId,
                                  type: "billing"
                                }));
                              }}
                            >
                              {t("billing.update")}
                            </Link>
                          </div>
                        )}

                        <Billing
                          clearErrors={clearErrors}
                          currentUser={currentUser}
                          handleUpgrade={handleUpgrade}
                        >
                          <div className="card__link">
                            <PersonaButtonWrapper>
                              <Link
                                onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  store.dispatch(fetchStripePortalUrl({
                                    primarySubscriptionId,
                                    type: "invoices"
                                  }));
                                }}
                              >
                                {t("billing.viewInvoices")}
                              </Link>
                            </PersonaButtonWrapper>
                          </div>
                        </Billing>
                      </>
                    </div>
                  )}
                </>
              ))}
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

Profile.defaultProps = {
  profileInfo: {},
  currentUser: {},
  notifications: {},
  errorCheckout: undefined
};

Profile.propTypes = {
  t: PropTypes.func.isRequired,
  updateProfileInfo: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  profileInfo: PropTypes.shape({
    is_locked: PropTypes.bool,
    subscription: PropTypes.shape({}),
    agency: PropTypes.string,
    agency_name: PropTypes.string,
    email: PropTypes.string,
    full_name: PropTypes.string,
    phone: PropTypes.string,
    worker_count: PropTypes.number,
    card: PropTypes.shape({
      last4: PropTypes.oneOfType([PropTypes.string]),
      exp_month: PropTypes.oneOfType([PropTypes.string]),
      exp_year: PropTypes.oneOfType([PropTypes.string])
    }),
    customer_billing_info: PropTypes.shape({})
  }),
  currentUser: PropTypes.shape({
    is_pro_account: PropTypes.bool,
    responses_quota: PropTypes.number,
    responses_quota_used: PropTypes.number,
    stripe_service_portal_url: PropTypes.string,
    integration_key: PropTypes.string,
    users_amount: PropTypes.number,
    sms_enabled: PropTypes.bool,
    sms_quota: PropTypes.number,
    sms_quota_used: PropTypes.number
  }),
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  notifications: PropTypes.shape({
    is_trial_period: PropTypes.bool,
    trial_period_end: PropTypes.bool
  }),
  updateAgency: PropTypes.func.isRequired,
  errorCheckout: PropTypes.string
};

export default withLoaderScreen(withTranslation()(Profile));
