/* eslint-disable import/prefer-default-export */
export enum StripeVerificationStatus {
  // SHOULD TRIGGER PERSONA VERIFICATION
  UNVERIFIED = "unverified",
  FAILED = "failed",
  PROCESSING = "processing",
  // SHOULD NOT TRIGGER PERSONA VERIFICATION
  VERIFIED = "verified",
  VERIFIED_MANUAL = "verified_manual",
  PENDING_VERIFICATION = "pending_verification"
}
