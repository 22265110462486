/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { store } from "store";
import { getCheckoutLink } from "store/modules/profile/actions";
import { withTranslation } from "react-i18next";

const PlanInfoFooter = ({ width, index, currencyPlan, isApi, t }) => {
  const handleSelectPlan = () => {
    if (index === 2 || isApi) {
      if (window.Beacon) {
        window.Beacon("open");
        window.Beacon("navigate", "/ask/message/");
        window.Beacon("prefill", {
          subject: `${isApi ? "Build" : "Enterprise"} plan quote`,
          text: `Hello, I would like to request a quote for the Willo "${isApi ? "Build" : "Enterprise"}" plan`
        });
      }
    } else {
      store.dispatch(getCheckoutLink(currencyPlan.key));
    }
  };

  return (
    <div className="plan-info">
      {!isApi && (
        <div
          className={classNames("plan-info__content", `column__${index}`)}
          style={{
            width
          }}
        >
          {index === 1 || index === 0 ? (
            "-"
          ) : (
            <div className="check-icon flex items-center justify-center">
              <FaCheck color="white" />
            </div>
          )}
        </div>
      )}
      <div
        className={classNames("plan-info__content", `column__${index}`)}
        style={{
          width
        }}
      >
        <div className="check-icon flex items-center justify-center">
          <FaCheck color="white" />
        </div>
      </div>
      <div
        className="pricing_bottom-row-content"
        style={{
          height: 80
        }}
        onClick={handleSelectPlan}
      >
        {(isApi || index === 2) ? t("plans.contactUs") : t("plans.selectPlan")}
      </div>
    </div>
  );
};
PlanInfoFooter.propTypes = {
  currencyPlan: PropTypes.shape({
    key: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  isApi: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(PlanInfoFooter);
