/* eslint-disable max-len */
import React from "react";
import PageWrapper from "hoc/PageWrapper";

import Lottie from "react-lottie";
import animationData from "assets/images/candidate/error.json";

import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";
import { useTranslation } from "react-i18next";
import { isCandidateRoute } from "mixins/helperCandidate";
import PropTypes from "prop-types";
import PrivateChat from "components/PrivateChat";
import PrivateChatCandidate from "components/PrivateChatCandidate";

import "./styles.scss";

export const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const GlobalErrorFallback = ({
  error
}) => {
  const { t } = useTranslation();

  const message = {
    subject: t("errors.errorFallback.email.subject", { error: "JS Error: Generic" }),
    text: t("errors.errorFallback.email.body")
  };


  return (
    <>
      <PageWrapper
        prefix="complete"
        header={<Header />}
        footer={<Footer isPublic />}
      >
        <div className="interview-complete__wrapper" data-sentry-unmask>
          <div className="interview-complete" data-sentry-unmask>
            <Lottie options={defaultOptions} height={200} width={300} />

            <div className="interview-complete__text-box" data-sentry-unmask>
              <h3 className="interview-complete__title" data-sentry-unmask>
                {t("errors.errorFallback.title")}
              </h3>

              <p className="interview-complete__text" data-sentry-unmask>
                {t("errors.errorFallback.subtitle")}
              </p>

              <p className="interview-complete__text" style={{ color: "#E83B3B" }} data-sentry-unmask>
                {error?.message}
              </p>

              <button
                style={{
                  padding: "12px 94px",
                  width: "100%",
                  fontSize: 16,
                  fontWeight: "medium"
                }}
                className="
                  n-bg-purple-100
                  n-x-lg
                  n-button__large
                  n-white
                "
                type="button"
                onClick={() => {
                  if (window.Beacon) {
                    window.Beacon("open");
                    window.Beacon("navigate", "/ask/message/");
                    window.Beacon("prefill", message);
                  }
                }}
                data-sentry-unmask
              >
                {t("errors.errorFallback.contactSupport")}
              </button>
            </div>
          </div>
        </div>

        { isCandidateRoute && error?.message ? <PrivateChatCandidate errorMessage={error?.message} /> : <PrivateChat errorMessage={error?.message} /> }
      </PageWrapper>
    </>
  );
};

GlobalErrorFallback.defaultProps = {
  error: undefined
};

GlobalErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string
  })
};

export default GlobalErrorFallback;
