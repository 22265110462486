/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, Switch } from "react-router-dom";

import PrivateChat from "containers/PrivateChat";
import { getHealthcheckError } from "store/modules/widgets/selectors";
import { connect } from "react-redux";
import { lazyRetry } from "mixins/helpers";
import useVerificationStore from "store/verificationStore.ts";
import { StripeVerificationStatus } from "constants/stripe.ts";
import { getIsEmailVerified } from "store/modules/users/selectors";
import RateLimitingPage from "../RateLimiting/RateLimitingPage";

const TrialCompleted = React.lazy(() =>
  lazyRetry(() => import("containers/Billing/TrialCompleted")));
const SubscriptionFailed = React.lazy(() =>
  lazyRetry(() => import("containers/Billing/SubscriptionFailed")));
const Plans = React.lazy(() =>
  lazyRetry(() => import("containers/Billing/Plans")));
const Notifications = React.lazy(() =>
  lazyRetry(() => import("containers/Notifications")));
const Users = React.lazy(() =>
  lazyRetry(() => import("containers/Users/UsersContainer")));
const Onboarding = React.lazy(() =>
  lazyRetry(() => import("containers/Onboarding")));
const Security = React.lazy(() =>
  lazyRetry(() => import("containers/Security")));
const StripeSession = React.lazy(() =>
  lazyRetry(() => import("containers/Billing/StripeSession")));
const DownloadNotification = React.lazy(() =>
  lazyRetry(() => import("containers/DownloadNotification")));
const IdvCheckoutSession = React.lazy(() =>
  lazyRetry(() => import("containers/Connect/IdvCheckoutSession/IdvCheckoutSession")));
const AccountVerification = React.lazy(() =>
  lazyRetry(() => import("views/Auth/AccountVerification/AccountVerification.tsx")));
const EmailVerificationPage = React.lazy(() =>
  lazyRetry(() => import("views/Auth/EmailVerification/EmailVerification.tsx")));
const StripeVerificationFailed = React.lazy(() =>
  lazyRetry(() => import("views/Auth/StripeVerificationFailed/StripeVerificationFailed.tsx")));

const PrivateRoute = ({
  isAuthenticated,
  saveRoute,
  savingRoute,
  isTrialCompleted,
  isSubscriptionFailed,
  isSetupCompleted,
  hasAdminRight,
  path,
  healthcheckError,
  isAccountVerified,
  isMfaRequired,
  isEmailVerified,
  ...rest
}) => {
  const { verificationStatus } = useVerificationStore();
  const isStripeVerificationFailed = verificationStatus === StripeVerificationStatus.FAILED;
  const hasRateLimitingIssue = healthcheckError === "api_rate_limiting";

  useEffect(() => {
    if (!isAuthenticated) {
      saveRoute({
        pathname: rest.location.pathname,
        search: rest.location.search
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && savingRoute) {
      saveRoute(null);
    }
  }, [isAuthenticated]);


  return isAuthenticated ? (
    <>
      {hasRateLimitingIssue ? (
        <RateLimitingPage />
      ) : (
        <>
          <Notifications />
          <DownloadNotification />
          {
            isStripeVerificationFailed ? (
              <Switch>
                <Route path="/stripe-verification-failed" exact component={StripeVerificationFailed} />
                <Redirect to="/stripe-verification-failed" />
              </Switch>
            ) : !isAccountVerified ? (
              <Switch>
                <Route path="/account-verification" exact component={AccountVerification} />
                <Redirect to="/account-verification" />
              </Switch>
            ) : !isEmailVerified ? (
              <Switch>
                <Route path="/email-verification" exact component={EmailVerificationPage} />
                <Redirect to="/email-verification" />
              </Switch>
            ) : isMfaRequired ? (
              <Switch>
                <Route path="/security" exact component={Security} />
                <Redirect to="/security" />
              </Switch>
            ) : isSubscriptionFailed ? (
              <Switch>
                <Route
                  path="/subscription-failed"
                  exact
                  component={SubscriptionFailed}
                />
                <Route path="/security" exact component={Security} />
                <Route
                  path="/stripe-session/:id"
                  exact
                  component={StripeSession}
                />
                <Route
                  path="/connect/:type/:id"
                  exact
                  component={IdvCheckoutSession}
                />
                {hasAdminRight && <Route path="/plans" exact component={Plans} />}
                <Redirect to="/subscription-failed" />
              </Switch>
            ) : isTrialCompleted ? (
              <Switch>
                <Route path="/trial-completed" exact component={TrialCompleted} />
                <Route path="/users" exact component={Users} />
                <Route path="/security" exact component={Security} />
                <Route
                  path="/stripe-session/:id"
                  exact
                  component={StripeSession}
                />
                <Route
                  path="/connect/:type/:id"
                  exact
                  component={IdvCheckoutSession}
                />
                {hasAdminRight && <Route path="/plans" component={Plans} />}
                <Redirect to="/trial-completed" />
              </Switch>
            ) : !isSetupCompleted ? (
              <Switch>
                <Route path="/onboarding" exact component={Onboarding} />
                <Route path="/security" exact component={Security} />
                <Redirect to="/onboarding" />
              </Switch>
            ) : savingRoute ? (
              <Redirect to={savingRoute} />
            ) : (
              <Route {...rest} />
            )}
          <PrivateChat />
        </>
      )}
    </>
  ) : (
    <Redirect to="/sign-in" />
  );
};

PrivateRoute.defaultProps = {
  savingRoute: null,
  healthcheckError: undefined
};

PrivateRoute.propTypes = {
  saveRoute: PropTypes.func.isRequired,
  savingRoute: PropTypes.shape({}),
  isAuthenticated: PropTypes.bool.isRequired,
  isTrialCompleted: PropTypes.bool.isRequired,
  isSubscriptionFailed: PropTypes.bool.isRequired,
  hasAdminRight: PropTypes.bool.isRequired,
  isSetupCompleted: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  healthcheckError: PropTypes.string
};

const mapStateToProps = state => ({
  healthcheckError: getHealthcheckError(state),
  isEmailVerified: getIsEmailVerified(state)
});

export default connect(mapStateToProps)(PrivateRoute);
