import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import type { BrowserOptions } from "@sentry/react";

import {
  getSentryBeforeSend,
  IGNORED_ERROR_MESSAGES
} from "mixins/helperLogging";
import App from "./App";

if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
  console.log("Sentry enabled");

  const sentryOptions: BrowserOptions = {
    dsn: `https://${process.env.REACT_APP_SENTRY_KEY}.ingest.sentry.io/${process.env.REACT_APP_SENTRY_ID}`,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: IGNORED_ERROR_MESSAGES,
    tracesSampleRate: process.env.REACT_APP_SENTRY_DEBUG_ENABLED === "true" ? 1.0 : 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,

    // Use unified beforeSend function from helperLogging
    beforeSend: getSentryBeforeSend(),

    attachStacktrace: true,

    integrations: [Sentry.replayIntegration({
      unmask: [".sentry-unmask, [data-sentry-unmask]"],
      maskAllText: false,
      stickySession: true,
      networkDetailAllowUrls: [
        window.location.origin,
        String(process.env.REACT_APP_BACKEND_URL)
      ]
    })]
  };

  Sentry.init(sentryOptions);
}

const container = document.getElementById("root");
ReactDOM.render(<App />, container);
