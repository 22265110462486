/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { getProfileInfo } from "store/modules/profile/selectors";
import useVerificationStore, { VerificationStatus } from "store/verificationStore.ts";

interface ButtonWrapperProps {
  children: ReactElement;
  profileInfo: any;
}

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({ children, profileInfo }) => {
  const { verificationStatus, initiateVerification } = useVerificationStore();

  const handleClick = async (e: React.MouseEvent) => {
    e?.preventDefault?.();

    if ([
      VerificationStatus.VERIFIED,
      VerificationStatus.VERIFIED_MANUAL,
      VerificationStatus.PENDING_VERIFICATION
    ].includes(verificationStatus as VerificationStatus)) {
      if (children.props.onClick) {
        children.props.onClick();
      }

      if (children.props.href || children.props.to) {
        const url = children.props.href || children.props.to;
        const { target } = children.props;

        setTimeout(() => {
          if (target === "_blank") {
            window.open(url, "_blank", "noopener,noreferrer");
          } else {
            window.location.href = url;
          }
        }, 10);
      }
    } else if (verificationStatus === VerificationStatus.PROCESSING) {
      if (window.Beacon) {
        window.Beacon("open");
        window.Beacon("navigate", "/ask/message/");
        window.Beacon("prefill", {
          subject: "ID Verification Status – Request for Update",
          text: `Hi Willo Support Team,

I've submitted all the required documents for my ID and agency verification, and I've noticed my status is currently marked as "processing."

Could you please provide an update on the status of my verification or let me know if any additional information is needed from my side?

Thanks,

${profileInfo.full_name}
${profileInfo.agency_name}`
        });
      }
    } else {
      await initiateVerification();
    }
  };

  return React.cloneElement(children, {
    ...children.props,
    onClick: handleClick
  });
};

const mapStateToProps = (state: any) => ({
  profileInfo: getProfileInfo(state)
});

export default connect(mapStateToProps)(ButtonWrapper);
