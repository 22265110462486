/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import getBlobDuration from "get-blob-duration";

import MobilePlayer from "components/Video/Recording/Mobile/JobPlayer";
import Message from "components/Common/Message";
import Buttons from "components/Video/Recording/JobButtons";
import { formTimeString } from "mixins/helperVideoRecording";

import ErrorModal from "components/Common/Error/ErrorModal";
import handlePlayFromRef from "utils/audio";
import { logErrors } from "mixins/helperLogging";
import { getCurrentUserId } from "store/modules/users/selectors";
import { store } from "store";
import { MAX_UPLOAD_VIDEO_SIZE } from "../../../../../constants";

const maxDuration = 180;

const VideoRecording = ({
  t,
  videoBlob,
  videoLink,
  changeVideoBlob,
  clearErrors,
  videoError
}) => {
  const [cashedVideo, setCashedVideo] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isShowPlayButton, setShowPlayButton] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [isMediaBlocked, setIsMediaBlocked] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [hasEBML, setHasEBML] = useState(true);
  const [isEBMLCalled, setIsEBMLCalled] = useState(false);

  const videoPlayer = useRef();
  const videoInput = useRef();
  const videoFile = useRef();

  const setStatusVideoComplete = () => {
    setIsRecording(false);
    setIsCompleted(true);
    setShowPlayButton(true);
  };

  useEffect(() => {
    if (videoBlob || videoLink) {
      setStatusVideoComplete();
    }
  }, []);

  useEffect(() => {
    if (typeof EBML === "undefined") {
      const script = document.createElement("script");
      script.src = "https://www.webrtc-experiment.com/EBML.js";
      script.async = true;

      script.onload = () => {
        console.log("EBML is successfully loaded and ready to use!", typeof EBML !== "undefined");
        setHasEBML(typeof EBML !== "undefined");
      };

      script.onerror = err => {
        setHasEBML(false);
        setIsEBMLCalled(true);

        const userId = getCurrentUserId(store.getState());

        logErrors({ error: new Error("Failed to load EBML.js"), payload: err, context: { userId, type: "recruiter" } });
      };

      document.body.appendChild(script);
    } else {
      setIsEBMLCalled(true);
      setHasEBML(true);
    }
  }, []);

  const onFileUpload = e => {
    const result = e.target.files[0];
    const isBigBlobSize = result && result.size > MAX_UPLOAD_VIDEO_SIZE;

    if (isBigBlobSize) {
      warningMessage(t("errors.videoTooBig"));
      return;
    }

    const blobUrl = result && URL.createObjectURL(result);

    setCashedVideo(blobUrl);
    changeVideoBlob(result);
  };

  const playCachedVideo = () => {
    handlePlayFromRef(videoPlayer.current);
    videoPlayer.current.onended = () => {
      setShowPlayButton(true);
    };

    setShowPlayButton(false);
  };

  const setStatusVideoStart = () => {
    videoInput.current.value = null;
    videoInput.current.click();

    setWarningMessage(null);
    setCashedVideo(null);
    changeVideoBlob(null);
    clearErrors();
  };

  const setWarning = message => {
    setIsRecording(false);
    setIsCompleted(false);
    setWarningMessage(message);
  };

  const stopRecord = useCallback(async () => {
    try {
      return new Promise(async () => {
        const duration = videoBlob && (await getBlobDuration(videoBlob));

        if (duration > maxDuration) {
          setWarning(
            t("errors.videoTooLong", {
              answerTime: formTimeString(maxDuration * 1000)
            })
          );
          return;
        }

        setWarningMessage("");

        const blobUrl = videoBlob && URL.createObjectURL(videoBlob);

        setCashedVideo(blobUrl);
        changeVideoBlob(videoBlob);

        setStatusVideoComplete({
          ref: videoPlayer
        });
      });
    } catch (e) {
      // @TODO: Change to error logging
    }

    return false;
  }, [videoBlob]);

  const startRecord = () => {
    setStatusVideoStart();
    setShowPlayButton(false);
  };

  const handleClickUpload = () => videoFile.current.click();

  return (
    <>
      <Col md={{ span: 7 }} xs={{ span: 12 }} className="job__video-player">
        <MobilePlayer
          isShowPlayButton={isShowPlayButton}
          videoBlob={videoBlob}
          videoLink={videoLink}
          playCachedVideo={playCachedVideo}
          onFileUpload={onFileUpload}
          videoPlayerRef={videoPlayer}
          videoInputRef={videoInput}
          videoFileRef={videoFile}
          cashedVideo={cashedVideo}
          isMediaBlocked={isMediaBlocked}
          videoError={videoError}
          hasEBML={hasEBML}
          isEBMLCalled={isEBMLCalled}
        />
      </Col>
      <Col md={{ span: 5 }} xs={{ span: 12 }}>
        <p className="job__video-title n-font-large n-font-medium-weight n-grey-100">
          {t("job.preview.titleVideo")}
        </p>
        <p className="job__video-description n-font-medium n-grey-100">
          {t("job.preview.descriptionVideo")}
        </p>
        {warningMessage && <Message message={warningMessage} error />}

        <Buttons
          isRecording={isRecording}
          isCompleted={isCompleted}
          startRecord={startRecord}
          stopRecord={stopRecord}
          isBlobEmpty={!cashedVideo}
          isMediaBlocked={isMediaBlocked}
          setIsMediaBlocked={setIsMediaBlocked}
          onClickUpload={handleClickUpload}
          hasEBML={hasEBML}
          isEBMLCalled={isEBMLCalled}
        />
      </Col>

      <ErrorModal
        show={showErrorModal}
        hide={() => setShowErrorModal(false)}
        t={t}
      />
    </>
  );
};

VideoRecording.propTypes = {
  t: PropTypes.func.isRequired,
  videoBlob: PropTypes.shape.isRequired,
  videoError: PropTypes.string.isRequired,
  videoLink: PropTypes.string.isRequired,
  changeVideoBlob: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default withTranslation()(VideoRecording);
