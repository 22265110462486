import { connect } from "react-redux";

import { saveRoute } from "store/modules/widgets/actions";
import { getToken } from "store/modules/auth/selectors";
import { getIsTrialCompleted, getIsSubscriptionFailed, getNotifications, getIsNotificationsLoading } from "store/modules/notifications/selectors";
import PrivateRoute from "components/Common/PrivateRoute";
import { getCurrentUser, getIsAccountVerified, getIsMfaRequired } from "store/modules/users/selectors";

const mapStateToProps = state => {
  const {
    is_admin_user: hasAdminRight = false
  } = getNotifications(state);

  const user = getCurrentUser(state);
  const isSetupCompleted = user?.is_setup_completed ?? true;

  return {
    savingRoute: state.widgets.savingRoute,
    isAuthenticated: Boolean(getToken(state)),
    isTrialCompleted: getIsTrialCompleted(state),
    isSubscriptionFailed: getIsSubscriptionFailed(state),
    hasAdminRight,
    isSetupCompleted,
    notificationsLoading: getIsNotificationsLoading(state),
    isAccountVerified: getIsAccountVerified(state),
    isMfaRequired: getIsMfaRequired(state)
  };
};

const mapDispatchToProps = {
  saveRoute
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
