import enTranslation from "./translations/en.json";
import zhTranslation from "./translations/zh.json";
import zhchtTranslation from "./translations/zh-cht.json";
import hiTranslation from "./translations/hi.json";
import esTranslation from "./translations/es.json";
import frTranslation from "./translations/fr.json";
import arTranslation from "./translations/ar.json";
import ruTranslation from "./translations/ru.json";
import idTranslation from "./translations/id.json";
import deTranslation from "./translations/de.json";
import nlTranslation from "./translations/nl.json";
import elTranslation from "./translations/el.json";
import cyTranslation from "./translations/cy.json";
import uaTranslation from "./translations/ua.json";
import itTranslation from "./translations/it.json";
import koTranslation from "./translations/ko.json";
import jaTranslation from "./translations/ja.json";
import htTransaltion from "./translations/ht.json";

export const DEFAULT_LANGUAGE = "en";

export default [
  {
    value: "en",
    text: "English (EN)",
    translation: enTranslation
  },
  {
    value: "zhtw",
    text: "繁體中文 (ZH-CHT)",
    translation: zhchtTranslation
  },
  {
    value: "zh",
    text: "简体中文 (ZH)",
    translation: zhTranslation
  },
  {
    value: "hi",
    text: "हिन्दी, हिंदी (HI)",
    translation: hiTranslation
  },
  {
    value: "es",
    text: "Español (ES)",
    translation: esTranslation
  },
  {
    value: "fr",
    text: "Français (FR)",
    translation: frTranslation
  },
  {
    value: "ar",
    text: "العربية (AR)",
    translation: arTranslation
  },
  {
    value: "ru",
    text: "Русский (RU)",
    translation: ruTranslation
  },
  {
    value: "id",
    text: "Bahasa Indonesia (ID)",
    translation: idTranslation
  },
  {
    value: "de",
    text: "Deutsch (DE)",
    translation: deTranslation
  },
  {
    value: "nl",
    text: "Nederlands (NL)",
    translation: nlTranslation
  },
  {
    value: "el",
    text: "Ελληνικά (EL)",
    translation: elTranslation
  },
  {
    value: "cy",
    text: "Cymraeg (CY)",
    translation: cyTranslation
  },
  {
    value: "ua",
    text: "Українська (UA)",
    translation: uaTranslation
  },
  {
    value: "it",
    text: "Italiano (IT)",
    translation: itTranslation
  },
  {
    value: "ko",
    text: "한국어 (KO)",
    translation: koTranslation
  },
  {
    value: "ja",
    text: "日本語 (JA)",
    translation: jaTranslation
  },
  {
    value: "ht",
    text: "Haitian Creole (HT)",
    translation: htTransaltion
  }
];
