/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isFirefox, isMobileSafari, isMobileOnly, isTablet, isChrome, isIOS, isAndroid } from "react-device-detect";

import { checkIfThisCurrentQuestion, canPlayExtension, MEDIA_ERROR_CODE } from "mixins/helperVideoRecording";
import Message from "components/Common/Message";

import "./styles.scss";

const PlayerMobile = ({
  t,
  videoBlob,
  videoPlayerRef,
  videoInputRef,
  question,
  savedAnswers,
  onFileUpload,
  cashedVideo,
  remoteLink,
  hasRetakesAvailable,
  isMediaUploadInterrupted
}) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [videoError, setVideoError] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [isVideoPlayable, setIsVideoPlayable] = useState(true);
  const loadAttemptCount = useRef(0);
  const timeUpdateCount = useRef(0);
  const timeoutRef = useRef(null);

  const { isCurrentQuestionSavedAnswer, savedAnswer } = checkIfThisCurrentQuestion(
    savedAnswers,
    question
  );

  const canPlay = useMemo(() => {
    if (!savedAnswer?.media_extension || videoBlob) return "supported";
    return canPlayExtension(savedAnswer?.media_extension.toLowerCase());
  }, [savedAnswer, videoBlob]);

  const src = useMemo(() => {
    const link = isIOS
      ? cashedVideo || remoteLink
      : remoteLink && isCurrentQuestionSavedAnswer
        ? remoteLink : cashedVideo;

    if (!link) return null;

    // Firefox, Safari, and Chrome with cached video don't need the timestamp parameter
    if (isMobileSafari || isFirefox || (isChrome && cashedVideo)) {
      return link;
    }

    // Add start time parameter to force seeking to beginning
    return `${link}#t=0.001`;
  }, [remoteLink, cashedVideo, isCurrentQuestionSavedAnswer]);

  const videoProps = useMemo(() => {
    const baseProps = {
      playsInline: true,
      crossOrigin: "anonymous",
      preload: "metadata"
    };

    switch (true) {
    case isChrome && (isMobileOnly || isTablet):
      return {
        ...baseProps,
        autoPlay: false,
        preload: "metadata"
      };
    case (isMobileSafari || isFirefox):
      return {
        ...baseProps,
        autoPlay: false
      };
    default:
      return baseProps;
    }
  }, []);

  const [height, setHeight] = useState("240px");

  useEffect(() => {
    if (src) {
      setIsVideoLoading(true);
      setVideoError(null);
      setIsVideoPlayable(true);
      loadAttemptCount.current = 0;
      timeUpdateCount.current = 0;
    }
  }, [src]);

  useEffect(() => {
    if (!videoBlob && !isCurrentQuestionSavedAnswer && !isMediaUploadInterrupted) {
      setShowPlayer(false);
      return;
    }
    setShowPlayer(true);
  }, [savedAnswers, videoBlob, question.key,
    isCurrentQuestionSavedAnswer, isMediaUploadInterrupted]);

  const handleMetadata = event => {
    const { videoWidth, videoHeight } = event?.target || {};

    if (videoWidth && videoHeight) {
      const clientHeight = `${Math.ceil((videoHeight / videoWidth) *
        (videoPlayerRef?.current?.parentElement?.clientWidth || window.innerWidth))}px`;
      setHeight(clientHeight);
    } else {
      setHeight("240px");
    }

    setIsVideoLoading(false);
  };

  const handleError = useCallback(error => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    const errorEvent = error?.target?.error;
    const errorCode = errorEvent?.code;

    setIsVideoLoading(false);
    setIsVideoPlayable(false);

    let errorText = "Unknown video playback error";

    switch (errorCode) {
    case MEDIA_ERROR_CODE.MEDIA_ERR_SRC_NOT_SUPPORTED:
      errorText = `
          ${t(`errors.${hasRetakesAvailable ? "interruptedPreviousUpload" : "interruptedVideoUpload"}.0`)}
          ${t("errors.interruptedVideoUpload.1")}
        `;
      break;
    default:
      errorText = t("errors.genericVideoError.0");
    }

    setVideoError(errorText);

    if (errorCode === MEDIA_ERROR_CODE.MEDIA_ERR_NETWORK && loadAttemptCount.current < 2) {
      loadAttemptCount.current += 1;

      timeoutRef.current = setTimeout(() => {
        if (videoPlayerRef?.current) {
          videoPlayerRef.current.load();
        }
        timeoutRef.current = null;
      }, 1500);
    }
  }, [hasRetakesAvailable, t, videoPlayerRef]);

  const handleTimeUpdate = useCallback(() => {
    timeUpdateCount.current += 1;

    if (timeUpdateCount.current > 3 && videoError) {
      setVideoError(null);
      setIsVideoPlayable(true);
    }
  }, [videoError]);

  const handleCanPlay = useCallback(() => {
    setIsVideoLoading(false);
    setIsVideoPlayable(true);

    if ((isIOS || isAndroid) && videoPlayerRef?.current) {
      videoPlayerRef.current.play().catch(err => {
        // Autoplay failed - this is normal on mobile devices that require user interaction
        console.log("Autoplay prevented:", err);
      });
    }
  }, [videoPlayerRef]);

  const handleVideoClick = useCallback(() => {
    if (videoPlayerRef?.current && isVideoPlayable) {
      videoPlayerRef.current.play().catch(() => {
        setVideoError("Couldn't play video - please try again");
      });
    }
  }, [isVideoPlayable, videoPlayerRef]);

  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const style = { height };

  return (
    <>
      <input
        ref={videoInputRef}
        type="file"
        accept="video/*"
        capture="user"
        className="mobile-player__video-input"
        onChange={onFileUpload}
      />

      {showPlayer && (
        <>
          {!canPlay && <Message error message={t("errors.previewUnavailable")} />}
          {videoError && <Message error message={videoError} />}

          <div className="mobile-player" style={style}>
            {src && (
              <video
                {...videoProps}
                ref={videoPlayerRef}
                src={src}
                controls={!!src && isVideoPlayable}
                muted={isVideoLoading}
                onClick={handleVideoClick}
                onLoadedMetadata={handleMetadata}
                onError={handleError}
                onCanPlay={handleCanPlay}
                onTimeUpdate={handleTimeUpdate}
                style={style}
              />
            )}

            {/* Overlay for devices that need click to play */}
            {isVideoPlayable && !isVideoLoading && (isIOS || isAndroid) && (
              <div
                className="mobile-player__play-overlay"
                onClick={handleVideoClick}
                onKeyDown={() => {}}
                tabIndex={0}
                role="button"
              >
                <div className="mobile-player__play-button" />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

PlayerMobile.defaultProps = {
  question: {},
  savedAnswers: [],
  cashedVideo: undefined,
  remoteLink: undefined,
  videoBlob: undefined,
  videoPlayerRef: undefined,
  videoInputRef: undefined,
  onFileUpload: undefined
};

PlayerMobile.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    key: PropTypes.string
  }),
  savedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      remote_link: PropTypes.string
    })
  ),
  remoteLink: PropTypes.string,
  cashedVideo: PropTypes.string,
  videoBlob: PropTypes.shape({}),
  videoPlayerRef: PropTypes.shape({
    current: PropTypes.object
  }),
  videoInputRef: PropTypes.shape({
    current: PropTypes.object
  }),
  onFileUpload: PropTypes.func
};

export default withTranslation()(PlayerMobile);
