/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import PageWrapper from "hoc/PageWrapper";
import Header from "containers/Common/HeaderContainer";
import { copyToClipboard } from "mixins/helpers";
import { LinkIcon } from "mixins/svgIcons";
import TooltipSuccess from "components/Common/Tooltip/Success";
import animationData from "assets/images/candidate/start.json";
import PersonaButtonWrapper from "components/PersonaVerification/PersonaButtonWrapper.tsx";
import "./styles.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const TrialCompleted = ({
  t,
  notifications: {
    is_admin_user: isAdmin = false
  },
  currentUser: { name }
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tootipText, setTooltipText] = useState("");
  const buttonCopy = useRef(null);

  const handleCopy = () => {
    setTooltipText(t("modals.showCase.successTip"));
    setShowTooltip(true);

    const link = `${window.location.origin}/plans?utm_source=internal&utm_medium=user&utm_campaign=trial_complete_link`;
    copyToClipboard(link);

    const hide = setTimeout(() => {
      clearTimeout(hide);
    }, 3000);
  };

  const handleShowTooltip = useCallback(() => {
    setTooltipText(
      isMobile ? t("modals.showCase.successTip") : t("modals.showCase.copyTip")
    );
    setShowTooltip(true);
  }, []);

  const handleHideTooltip = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const openBeacon = () => {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
      window.Beacon("prefill", {
        subject: "Trial Complete",
        text:
          "Hello, I would like to learn more about my options following a trial"
      });
    }
  };

  return (
    <PageWrapper header={<Header />}>
      <Container className="trial-completed__container">
        <Row>
          <Col>
            <Lottie
              options={defaultOptions}
              height={200}
              width={200}
            />
            <h1 className="n-font-giant n-text-center trial-completed__header">
              {t("trialPeriod.header", { name })}
            </h1>
            <p className="n-font-medium n-text-center trial-completed__text">
              {t("trialPeriod.waiting")}
            </p>
            <p className="n-font-medium n-text-center trial-completed__text">
              {t(
                !isAdmin
                  ? "trialPeriod.continueUser"
                  : "trialPeriod.continueOwner"
              )}
            </p>
            <p className="n-font-medium n-text-center trial-completed__text">
              {t(!isAdmin ? "trialPeriod.helpfulLink" : "trialPeriod.love")}
            </p>

            {!isAdmin ? (
              <>
                <button
                  className="n-button__medium n-bg-purple-100 n-white trial-completed__button"
                  type="button"
                  onClick={handleCopy}
                  onMouseEnter={handleShowTooltip}
                  onMouseLeave={handleHideTooltip}
                  ref={buttonCopy}
                >
                  <LinkIcon svgIconClass="" fill="#ffffff" />
                  <span>{t("button.copyLink")}</span>
                </button>
                <TooltipSuccess
                  position="bottom"
                  message={tootipText}
                  target={buttonCopy.current}
                  show={showTooltip}
                />
              </>
            ) : (
              <PersonaButtonWrapper>
                <Link
                  className="n-button__medium n-bg-purple-100 n-white trial-completed__link"
                  to="/plans?utm_source=internal&utm_medium=user&utm_campaign=trial_complete"
                >
                  {t("button.upgrade")}
                </Link>
              </PersonaButtonWrapper>
            )}

            <p className="n-font-small n-text-center trial-completed__support">
              {t("trialPeriod.prefer")}
              <button
                type="button"
                onClick={openBeacon}
                className="n-font-small trial-completed__support"
              >
                {t("trialPeriod.team")}
              </button>
            </p>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

TrialCompleted.defaultProps = {
  notifications: { is_admin_user: false },
  currentUser: {}
};

TrialCompleted.propTypes = {
  t: PropTypes.func.isRequired,
  notifications: PropTypes.shape({
    is_admin_user: PropTypes.bool,
    show_primary_subscription_unpaid_page: PropTypes.bool
  }),
  currentUser: PropTypes.shape({
    name: PropTypes.string,
    is_owner: PropTypes.bool
  })
};

export default withTranslation()(TrialCompleted);
