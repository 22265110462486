/* eslint-disable import/no-cycle */
import { takeEvery, call, select, put } from "redux-saga/effects";

import {
  handleOnboarding,
  handleOnboardingError,
  handleOnboardingResponse,
  refreshStatus,
  saveIsOnline,
  saveRefreshRoute,
  setHealthCheckError,
  setLoadingScreenMaintenance
} from "store/modules/widgets/actions";
import { getHealthcheckError, getIsOnline, getRefreshRoute } from "store/modules/widgets/selectors";
import { history, store } from "store";

import { getToken } from "store/modules/auth/selectors";

import { isEmpty, isNull } from "lodash";
import { getCandidateToken } from "store/modules/сandidates/selectors";
import { isCurrentUserCandidate } from "mixins/helperCandidate";
import { getRequest, getRequestWithToken } from "./api";


export function* handleOnboardingWorker({ payload: { key } }) {
  try {
    const token = yield select(getToken);

    if (key && token) {
      const response = yield call(getRequestWithToken, {
        token,
        url: `/api/agency/${key}/dashboard/`
      });

      yield put(handleOnboardingResponse(response.data));
    }
  } catch (error) {
    yield put(handleOnboardingError("Could not get data for onboarding"));
  }
}

export function* refreshStatusWorker() {
  try {
    yield put(setLoadingScreenMaintenance(true));
    const route = yield select(getRefreshRoute);
    const { pathname } = window.location;

    const recruiterToken = yield select(getToken);
    const candidateToken = yield select(getCandidateToken);

    const appToken = isCurrentUserCandidate ? candidateToken : recruiterToken;
    let response;
    if (appToken) {
      response = yield getRequestWithToken({
        url: "/api/healthcheck/",
        token: appToken
      });
    } else {
      response = yield getRequest({
        url: "/api/healthcheck/"
      });
    }

    if (!isEmpty(process.env.REACT_APP_API_TEST_URL)) {
      response = yield getRequestWithToken({
        fullUrl: `${process.env.REACT_APP_API_TEST_URL}/api/healthcheck/`,
        ...(appToken ? { token: appToken } : { })
      });
    }

    if (response.status === 200) {
      const isOnline = yield select(getIsOnline);

      if (!isOnline) yield put(saveIsOnline(true));

      if (pathname === "/maintenance") {
        yield put(saveRefreshRoute(null));
        history.push(route || "/dashboard");
      }
    } else if (pathname !== "/maintenance") {
      yield put(saveRefreshRoute({
        pathname: window.location.pathname,
        search: window.location.search
      }));
      history.push("/maintenance");
    }
  } catch (error) {
    console.log("[refreshStatusError]", error);

    const { pathname } = window.location;

    const healthcheckError = getHealthcheckError(store.getState());

    if (!isNull(healthcheckError) && !error?.message?.includes("Network Error")) {
      store.dispatch(setHealthCheckError(null));
    }

    if (pathname !== "/maintenance") {
      yield put(saveRefreshRoute({
        pathname: window.location.pathname,
        search: window.location.search
      }));

      history.push("/maintenance");
    }
  } finally {
    yield put(setLoadingScreenMaintenance(false));
  }
}

export function* widgetwWatcher() {
  yield takeEvery(handleOnboarding, handleOnboardingWorker);
  yield takeEvery(refreshStatus, refreshStatusWorker);
}

export default widgetwWatcher;
