/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import useInterviewTemplatesStore from "store/useTemplateJobStore.ts";
import { MinusIcon, PlusIcon } from "mixins/svgIcons";
import CollapsibleComponent from "components/Common/CollapsibleComponent.tsx";
import { useTranslation } from "react-i18next";
import TemplateQuestion from "./TemplateQuestion.tsx";

const QuestionList: React.FC = () => {
  const { template } = useInterviewTemplatesStore();
  const [showQuestions, setShowQuestions] = useState(true);
  const [showIntroduction, setShowIntroduction] = useState(true);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:flex-row md:items-start">
      <div className="md:max-w  -60 w-full">
        <div className="mb-8">
          <h2 className="text-sm font-medium text-main-link">{t("template.aboutTemplate")}</h2>
          <div className="text-black-90 mt-4">
            {template?.description}
          </div>
        </div>
      </div>

      <div className="md:ml-5">
        {
          template?.description && (
            <div className="pb-4 mb-4 border-tertiary-grey-10 border-b">
              <div className="flex flex-row items-center justify-between">
                <div className="text-lg font-medium text-black-90 mb-2">{t("template.introduction")}</div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setShowIntroduction(prev => !prev);
                  }}
                >
                  {showIntroduction ? (
                    <MinusIcon />
                  ) : (
                    <PlusIcon fill="black" width="20" height="20" />
                  )}
                </div>
              </div>

              <CollapsibleComponent show={showIntroduction}>
                <div>
                  {template?.description}
                </div>
              </CollapsibleComponent>
            </div>
          )
        }

        <div className="mb-8 flex flex-row items-center justify-between">
          <div className="text-lg font-medium text-black-90">{t("template.questions")}</div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setShowQuestions(prev => !prev);
            }}
          >
            {showQuestions ? (
              <MinusIcon />
            ) : (
              <PlusIcon fill="black" width="20" height="20" />
            )}
          </div>
        </div>

        <CollapsibleComponent show={showQuestions}>
          {template?.questions.map(question => (
            <TemplateQuestion
              key={question.id}
              question={question}
            />
          ))}
        </CollapsibleComponent>
      </div>
    </div>
  );
};

export default QuestionList;
