import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ROLE_ADMIN } from "configs/user/rolesConfig";
import { PLANS_NAMES } from "configs/plans";

import { logOut } from "store/modules/auth/actions";
import { setCurrentUser, getCurrentAgency } from "store/modules/users/actions";
import { fetchJobs } from "store/modules/jobs/actions";
import { handleOnboarding } from "store/modules/widgets/actions";
import {
  getNotifications,
  getIsTrialCompleted,
  getIsSubscriptionFailed,
  getIsTrialOrPaid
} from "store/modules/notifications/selectors";
import { getCurrentUser, getIsAccountVerified, getListOfUsers, getIsMfaRequired, getIsEmailVerified } from "store/modules/users/selectors";
import { getRefreshToken, getToken } from "store/modules/auth/selectors";
import { getProfileInfo } from "store/modules/profile/selectors";
import { fetchProfileInfo } from "store/modules/profile/actions";

import Header from "components/Common/Header/Main";

const mapStateToProps = state => {
  const { plan_name: planName } = getCurrentUser(state);
  const isFree = planName === PLANS_NAMES.freeForever;
  const {
    is_trial_period: isTrialPeriod,
    completedOnboarding = true
  } = getNotifications(state);

  const user = getCurrentUser(state);
  const isSetupCompleted = user?.is_setup_completed;

  return {
    isAuthenticated: Boolean(getToken(state)),
    isAdmin: getCurrentUser(state).role === ROLE_ADMIN,
    currentUser: getCurrentUser(state),
    profileInfo: getProfileInfo(state),
    usersList: getListOfUsers(state),
    isFree,
    isTrialCompleted: getIsTrialCompleted(state),
    isTrialPeriod,
    isSubscriptionFailed: getIsSubscriptionFailed(state),
    isSetupCompleted: isSetupCompleted && completedOnboarding,
    isTrialOrPaid: getIsTrialOrPaid(state),
    token: getToken(state),
    refreshToken: getRefreshToken(state),
    isAccountVerified: getIsAccountVerified(state),
    isMfaRequired: getIsMfaRequired(state),
    isEmailVerified: getIsEmailVerified(state)
  };
};

const mapDispatchToProps = {
  fetchJobs,
  setCurrentUser,
  handleOnboarding,
  logOut,
  getCurrentAgency,
  fetchProfileInfo
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
