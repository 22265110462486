/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useCallback } from "react";
import JobTemplateIcon from "assets/images/jobs-template/JobTemplateIcon.png";
import useInterviewTemplatesStore, { Template } from "store/useTemplateJobStore.ts";
import Spinner from "components/Common/Spinner";
import { Waypoint } from "react-waypoint";
import { history } from "store";
import { useTranslation } from "react-i18next";

interface JobsTemplateListProps {
  templates: Template[];
}

const JobsTemplateList = ({ templates }: JobsTemplateListProps) => {
  const {
    isLoading,
    isNextPageLoading,
    hasNextPage,
    currentPage,
    filterParams,
    getTemplates,
    getInterviewTemplate
  } = useInterviewTemplatesStore();

  const { t } = useTranslation();

  const handleLoadMore = useCallback(() => {
    if (!isNextPageLoading && hasNextPage) {
      getTemplates({
        ...filterParams,
        page: currentPage + 1
      });
    }
  }, [currentPage, filterParams, getTemplates, hasNextPage, isNextPageLoading]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div
        className="grid gap-6 pb-5 w-full md:mt-0 mt-5"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(min(248px, 100%), 1fr))",
          maxWidth: "100%",
          margin: "0 auto"
        }}
      >
        {templates.map(template => (
          <div
            key={template.key}
            className="rounded-2xl border-main-link border overflow-hidden w-full cursor-pointer"
            style={{ maxWidth: "248px", justifySelf: "center" }}
            onClick={() => {
              history.push(`/jobs/template/${template.key}`);
            }}
          >
            <div className="bg-light-brown relative">
              {template.is_featured && (
                <div className="px-3 py-1 text-xs text-main-link font-medium bg-white rounded-full w-fit absolute top-3 right-3">
                  {t("template.featured")}
                </div>
              )}
              <div className="flex items-center justify-center pt-3">
                <img src={JobTemplateIcon} className="w-full h-full" alt="job-template" />
              </div>
            </div>

            <div className="p-4">
              <div className="text-black-90 mb-2 h-6 line-clamp-1">
                {template.title}
              </div>
              <p className="text-xs text-main-link mb-2 font-medium">
                {template?.category?.name}
              </p>
              <p className="text-xs text-main-link mb-4">
                {template.question_count}
                {" "}
                {t("template.questions")}
              </p>
              <div
                className="text-sm text-main-link font-medium cursor-pointer border-main-purple border-b w-fit pb-1 hover:text-main-purple transition-colors"
                onClick={() => {
                  getInterviewTemplate(template.key);
                }}
              >
                {t("template.startUsingTemplate")}
              </div>
            </div>
          </div>
        ))}
        {hasNextPage && !isNextPageLoading && !isLoading && (
          <Waypoint onEnter={handleLoadMore} bottomOffset="-20%" />
        )}
        {isNextPageLoading && <Spinner size={10} />}
      </div>
    </div>
  );
};

export default JobsTemplateList;
