import Carousel, { CarouselItem } from "components/Common/Carousel/Carousel.tsx";
import React, { useEffect, useMemo } from "react";
import useOBLandingStore from "store/useOBLandingStore.ts";
import "./styles.scss";
import { MediaType, MediaTypeEnum } from "components/Common/Card/Base/types.ts";
import useScreenSize from "hooks/useScreenSize.tsx";
import { isMobile } from "react-device-detect";

const EmptyStateCarousel = (
) => {
  const { getArticles, articles } = useOBLandingStore();
  const screenSize = useScreenSize();

  const getComputedMaxWidth = () => {
    const emptyStateLanding = document.getElementById("empty-state-landing");


    if (emptyStateLanding) {
      const emptyStateStyle = window.getComputedStyle(emptyStateLanding);
      const paddingLeft = (screenSize.width - parseFloat(emptyStateStyle.maxWidth)) / 2;
      return paddingLeft;
    }

    return 0;
  };


  useEffect(() => {
    getArticles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedArticles: CarouselItem[] = useMemo(() => articles.map(article => ({
    id: article.key,
    title: {
      text: article.title
    },
    description: {
      text: "" // need to ask Dima
    },
    headerMedia: {
      src: article.image,
      type: MediaTypeEnum[article.type as MediaType]
    },
    author: article.author,
    viewsAmount: article.views_amount,
    openIn: article.open_in,
    url: article.url
  })), [articles]);

  return (
    <div
      className="pb-16"
      style={{
        paddingLeft: isMobile ? 16 : getComputedMaxWidth(),
        paddingRight: isMobile ? 16 : 50
      }}
    >
      <Carousel
        showArrows
        items={formattedArticles}
        title="Needing more help?"
        withProgress
      />
    </div>
  );
};

export default EmptyStateCarousel;
