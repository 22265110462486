/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

import Message from "components/Common/Message";
import Header from "containers/Common/HeaderContainer";
import JobsTable from "containers/Tables/JobsContainer";
import PageWrapper from "hoc/PageWrapper";
import withAbilityCreateJob from "hoc/withAbilityCreateJob";
import { PLANS_NAMES } from "configs/plans";

import { store } from "store";
import { clearCurrentJob, fetchJobs } from "store/modules/jobs/actions";
import useVerificationStore, { VerificationStatus } from "store/verificationStore.ts";
import { connect } from "react-redux";
import { getProfileInfo } from "store/modules/profile/selectors";
import EmptyStateLanding from "views/EmptyState/EmptyStateLanding.tsx";
import Filters from "./Filters";

const newFilter = { title: "", companies: [], owners: [], statuses: [] };

const Jobs = ({
  t,
  isCardInfoLoading,
  dataJobs = [],
  dataCompanies = {},
  history,
  clearBillingError,
  clearSetupState,
  clearQuestionsState,
  clearPreviewState,
  clearStatuses,
  clearErrors,
  clearSuccessMessage,
  success,
  error,
  currentUser: { plan_name: planName, key: currentUserKey },
  redirectTo,
  isLoadingFilters,
  filters,
  isJobsLoading,
  profileInfo
}) => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(newFilter);
  const [updated, setUpdated] = useState(false);
  const [lastFilter, setLastFilter] = useState(newFilter);
  const firstUpdate = useRef(true);
  const { verificationStatus, initiateVerification } = useVerificationStore();

  const clearFilter = () => {
    setSearch("");
    setFilter(newFilter);
  };

  const isFreeInterviewsFinished = useMemo(
    () => planName === PLANS_NAMES.freeForever && dataJobs.length > 0,
    [planName, dataJobs.length]
  );

  const isShowBtn = useMemo(() => typeof dataCompanies?.results !== "undefined"
    && dataCompanies?.results?.length, [
    dataCompanies?.results
  ]);

  const fetchData = (userKey, filterData) => {
    const withFilterUpdate =
      lastFilter.title === filterData.title &&
      (lastFilter.companies !== filter.companies ||
        lastFilter.owners !== filter.owners ||
        lastFilter.statuses !== filter.statuses);

    if (userKey) {
      store.dispatch(fetchJobs({ key: userKey, filter: filterData, withFilterUpdate }));
      setLastFilter(filterData);
    }
  };

  const fetchDataDebounce = debounce((userKey, filterData) => {
    fetchData(userKey, filterData);
  }, 500);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      fetchData(currentUserKey, filter);
      return;
    }
    if (updated) {
      setUpdated(false);
    } else {
      fetchDataDebounce(currentUserKey, filter);
    }
  }, [currentUserKey, filter]);

  useEffect(() => {
    clearErrors();
    clearSuccessMessage();
  }, [dataJobs.length]);

  useEffect(() => {
    setFilter({
      ...filter,
      companies: filter.companies,
      owners: filter.owners,
      statuses: filter.statuses
    });
    setUpdated(true);
  }, [filters]);

  useEffect(() => {
    store.dispatch(clearCurrentJob());
  }, []);

  // eslint-disable-next-line consistent-return
  const handleCreateJob = () => {
    if (isFreeInterviewsFinished) {
      if ([VerificationStatus.UNVERIFIED,
        VerificationStatus.IN_PROGRESS
      ].includes(verificationStatus)) {
        return initiateVerification();
      } if (verificationStatus === VerificationStatus.PROCESSING) {
        window.Beacon("open");
        window.Beacon("navigate", "/ask/message/");
        window.Beacon("prefill", {
          subject: "ID Verification Status – Request for Update",
          text: `Hi Willo Support Team,

                I've submitted all the required documents for my ID and agency verification, and I've noticed my status is currently marked as "processing."

                Could you please provide an update on the status of my verification or let me know if any additional information is needed from my side?

                Thanks,
                ${profileInfo.full_name}
                ${profileInfo.agency_name}
`
        });
        return;
      }

      return redirectTo(
        "/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_interviews"
      );
    }
    clearBillingError();
    clearStatuses();
    clearSetupState();
    clearQuestionsState();
    clearPreviewState();
    history.push("/jobs/create");
  };

  // @TODO: This is always true since jobs arent being fetched without showing the filters component
  const showEmptyState = useMemo(() =>
    (!isJobsLoading && !dataJobs?.length)
    && process.env.REACT_APP_ENABLE_OB_TEMPLATES === "true",
  [isJobsLoading, dataJobs]);

  if (showEmptyState) {
    return (
      <EmptyStateLanding handleCreateJob={handleCreateJob} />
    );
  }

  return (
    <PageWrapper header={<Header />}>
      <Container className="jobs">
        <Filters
          search={search}
          setSearch={setSearch}
          isLoading={isLoadingFilters}
          filters={filters}
          filter={filter}
          setFilter={setFilter}
          clearFilter={clearFilter}
          isShowBtn={isShowBtn}
          isCardInfoLoading={isCardInfoLoading}
          handleCreateJob={handleCreateJob}
          isFreeInterviewsFinished={isFreeInterviewsFinished}
          redirectTo={redirectTo}
        />

        <Col md={12}>
          {success || error ? (
            <div className="jobs__message">
              {success && <Message success message={t(success)} />}
              {error && <Message error message={t(error)} />}
            </div>
          ) : null}

          {!isJobsLoading && !isShowBtn ? (
            <p className="main-text n-font-medium n-text-center">
              {`${t("job.createCompany")} `}
              <a
                href="https://support.willo.video"
                target="_blank"
                rel="noopener noreferrer"
                className="main-text main-link n-purple-100"
              >
                {t("link.needHelp")}
              </a>
            </p>
          ) : null}
        </Col>

        <Row justify="center">
          <Col md={12}>
            <JobsTable
              filter={filter}
              clearFilter={clearFilter}
              updated={updated}
              setUpdated={setUpdated}
            />
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

Jobs.defaultProps = {
  success: undefined,
  error: undefined,
  dataCompanies: undefined,
  isJobsLoading: false
};

Jobs.propTypes = {
  t: PropTypes.func.isRequired,
  isCardInfoLoading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    length: PropTypes.number
  }).isRequired,
  filters: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.any),
    owners: PropTypes.arrayOf(PropTypes.any),
    statuses: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  dataJobs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataCompanies: PropTypes.shape({
    next: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.object)
  }),
  clearBillingError: PropTypes.func.isRequired,
  clearSetupState: PropTypes.func.isRequired,
  clearQuestionsState: PropTypes.func.isRequired,
  clearPreviewState: PropTypes.func.isRequired,
  clearStatuses: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  clearSuccessMessage: PropTypes.func.isRequired,
  success: PropTypes.string,
  error: PropTypes.string,
  currentUser: PropTypes.shape({
    plan_name: PropTypes.string,
    key: PropTypes.string
  }).isRequired,
  redirectTo: PropTypes.func.isRequired,
  isLoadingFilters: PropTypes.bool.isRequired,
  isJobsLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  profileInfo: getProfileInfo(state)
});

export default connect(mapStateToProps)(withAbilityCreateJob(withTranslation()(Jobs)));
