import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TooltipInfo from "components/Common/Tooltip/Info";
import DeleteInput from "components/Common/DeleteInput";
import {
  Profile,
  Mail,
  Key,
  Eye,
  TimerIcon,
  Search,
  Country,
  Check
} from "mixins/svgIcons";

const icons = {
  Profile: <Profile svgIconClass="input-icon" />,
  Mail: <Mail svgIconClass="input-icon" />,
  Key: <Key svgIconClass="input-icon" />,
  Timer: <TimerIcon svgIconClass="input-icon" />,
  Search: <Search svgIconClass="input-icon" />,
  Country: <Country svgIconClass="input-icon" />,
  Check: (
    <Check svgIconClass="input-icon" fill="#9095A3" height="14" width="14" />
  )
};

const Input = ({
  t,
  name,
  isInputWithDeleteBtn,
  inputChangedHandler,
  inputBlurHandler,
  inputDeletedHandler,
  labelClassName,
  formElement: {
    value,
    touched,
    icon,
    label,
    prefix,
    valid,
    supLabel,
    infoLabel,
    InfoElement,
    elementType,
    elementConfig,
    errorMessage,
    supLabelElement,
    className,
    inputClass = ""
  },
  inputContainerClassName,
  disabled,
  maxLength,
  ...props
}) => {
  const [isVisible, setVisibility] = useState(false);

  let addInfoElement = null;
  let inputElement = null;
  const inputClasses = ["input", inputClass];
  const inputShowPassword = "show-password";
  const inputElementClasses = ["input-element", className];

  if (prefix) {
    inputElementClasses.push(`input-element_${prefix}`);
  }

  if (value !== null && !valid && touched) {
    if (elementType === "password") {
      inputClasses.push("with-error");
    }

    inputElementClasses.push("invalid");
  }

  if (infoLabel) {
    addInfoElement = (
      <TooltipInfo position="top" message={t(`input.${infoLabel}`)} />
    );
  } else if (InfoElement) {
    addInfoElement = InfoElement;
  } else {
    infoLabel = "";
  }

  const { i18n } = useTranslation();

  let translatedErrorMessage = errorMessage;

  if (i18n.exists(`input.${errorMessage}`)) {
    translatedErrorMessage = t(`input.${errorMessage}`);
  }

  switch (elementType) {
  case "password":
    inputElement = (
      <div className="input-wrapper">
        {icons[icon]}
        <input
          id={`${elementType}-${name}`}
          key={`input-${name}`}
          className={inputElementClasses.join(" ")}
          {...elementConfig}
          placeholder={t(`input.${[elementConfig.placeholder]}`)}
          type={isVisible ? "text" : "password"}
          value={value}
          onChange={event => inputChangedHandler(event, name, elementType)}
          onBlur={inputBlurHandler && (() => inputBlurHandler(name))}
        />
        <button
          type="button"
          onClick={() => setVisibility(!isVisible)}
          className={inputShowPassword}
        >
          <Eye />
        </button>

        {value && !valid && touched && (
          <p className="input-error-message-form">{translatedErrorMessage}</p>
        )}
      </div>
    );
    break;
  default:
    inputElement = (
      <div className={`input-wrapper ${inputContainerClassName}`}>
        {icons[icon]}
        <input
          id={`${elementType}-${name}`}
          className={inputElementClasses.join(" ")}
          {...elementConfig}
          placeholder={t(`input.${[elementConfig.placeholder]}`)}
          value={value}
          onChange={event => inputChangedHandler(event, name, elementType)}
          disabled={disabled}
          onBlur={inputBlurHandler && (() => inputBlurHandler(name))}
          maxLength={maxLength}
          {...props}
        />
        {isInputWithDeleteBtn && (
          <DeleteInput
            deleted={event => inputDeletedHandler(event, name, elementType)}
          />
        )}
        {value !== null && !valid && touched && (
          <p className="input-error-message-form">{translatedErrorMessage}</p>
        )}
      </div>
    );
  }

  return (
    <div className={inputClasses.join(" ")}>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={elementType} className="label">
        {label && (
          // eslint-disable-next-line no-unneeded-ternary
          <span className={labelClassName ? labelClassName : "label__main"}>
            <span>{t(`input.${[label]}`)}</span>
            {addInfoElement}
          </span>
        )}
        {supLabel && (
          <span className="label__secondary">{t(`input.${[supLabel]}`)}</span>
        )}
        {supLabelElement}
      </label>
      {inputElement}
    </div>
  );
};

Input.defaultProps = {
  name: undefined,
  isInputWithDeleteBtn: undefined,
  inputChangedHandler: undefined,
  inputDeletedHandler: undefined,
  formElement: undefined,
  disabled: false,
  inputBlurHandler: undefined,
  maxLength: undefined,
  labelClassName: undefined
};

Input.propTypes = {
  t: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  isInputWithDeleteBtn: PropTypes.bool,
  inputChangedHandler: PropTypes.func,
  inputBlurHandler: PropTypes.func,
  inputDeletedHandler: PropTypes.func,
  formElement: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object
    ]),
    touched: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string,
    prefix: PropTypes.string,
    valid: PropTypes.bool,
    supLabel: PropTypes.string,
    infoLabel: PropTypes.string,
    elementType: PropTypes.string,
    elementConfig: PropTypes.object,
    errorMessage: PropTypes.string,
    supLabelElement: PropTypes.element,
    className: PropTypes.string,
    inputClass: PropTypes.string,
    InfoElement: PropTypes.node
  }),
  disabled: PropTypes.bool,
  maxLength: PropTypes.number
};

export default withTranslation()(Input);
