/* eslint-disable react-hooks/exhaustive-deps */
import QuestionList from "components/TemplateQuestion/QuestionList.tsx";
import React, { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import useInterviewTemplatesStore from "store/useTemplateJobStore.ts";
import PageWrapper from "hoc/PageWrapper";
import Header from "containers/Common/HeaderContainer";
import { Container } from "react-bootstrap";
import BaseHeader from "components/JobsTemplate/BaseHeader.tsx";
import MessagesIcon from "assets/images/landing/messaging-icon.png";
import useWindowResize from "hooks/useWindowResize.ts";
import { useTranslation } from "react-i18next";

interface MatchParams {
  id: string;
}

const JobTemplatePage: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { id }
  }
}) => {
  const { getInterviewTemplateById, template, getInterviewTemplate } = useInterviewTemplatesStore();
  const { isMobileAndTabletScreen } = useWindowResize();
  const { t } = useTranslation();
  useEffect(() => {
    if (id) {
      getInterviewTemplateById(id);
    }
  }, [id]);

  return (
    <PageWrapper header={<Header />}>
      <div className="min-h-screen">
        <BaseHeader
          showFingerPrint={false}
          bgColor="light-brown"
          leftContent={(
            <div>
              <a className="font-medium text-sm pt-5" href="/jobs/template">
                {t("template.templatesLibrary")}
              </a>
              <h1 className="text-3xl font-semibold text-gray-900">
                {template?.title}
              </h1>
            </div>
          )}
          rightContent={(
            <div
              className="md:pt-0 pt-4 relative"
              style={{
                width: 430
              }}
            >
              {
                isMobileAndTabletScreen ? null : (
                  <img src={MessagesIcon} alt="messaging-icon" className="w-full h-full" />
                )

              }
              <div
                className="md:absolute bottom-2"
                style={{
                  right: 100
                }}
              >
                <button
                  type="button"
                  className="bg-primary-purple px-4 py-2 rounded-full text-white font-medium "
                  onClick={e => {
                    e.preventDefault();
                    getInterviewTemplate(id);
                  }}
                >
                  {t("template.startUsingTemplate")}
                </button>
              </div>
            </div>
          )}
        />
        <Container>
          <div className="flex mt-5 md:flex-row flex-col">
            <QuestionList />
          </div>
        </Container>
      </div>
    </PageWrapper>

  );
};

export default withRouter(JobTemplatePage);
