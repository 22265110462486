/* eslint-disable camelcase */
import { TimerThinkingIcon } from "mixins/svgIcons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Question } from "store/useTemplateJobStore.ts";
import { secondsToMinutes } from "utils/number";


function indexToLetter(index: number) {
  return `${String.fromCharCode(65 + index)}. `;
}


const TemplateQuestion = ({ question }: {question: Question}) => {
  const { t } = useTranslation();
  const QuestionTypeLabel: Record<string, string> = {
    multiple_choice: t("job.quetions.types.multiple_choice"),
    text: t("job.quetions.types.text"),
    video: t("job.quetions.types.video"),
    audio: t("job.quetions.types.audio")
  };

  const renderAnswerBox = () => {
    switch (question.answer_type) {
    case "multiple_choice":
      return (
        <div className="space-y-2 mt-3">
          {
            question?.type_specific_data?.answer_options?.map((option, index) => (
              <div key={option.key} className="w-full rounded-md  text-black-90 font-medium">
                {indexToLetter(index)}
                {option.text}
              </div>
            ))
          }
        </div>
      );
    default:
      return null;
    }
  };

  return (
    <div
      className="mb-6 border-tertiary-grey-10 rounded-lg p-4"
      style={{
        borderWidth: 1
      }}
    >
      <div className="flex flex-row items-start justify-start gap-3">
        <div className="flex items-start gap-2 mb-2">
          <span className="text-sm text-gray-500">
            {question.order}
            .
          </span>
        </div>
        <div className="flex flex-col">
          <p
            className="text-gray-900"
            style={{
              marginTop: 2
            }}
          >
            {question.text}
          </p>
          {renderAnswerBox()}
          <div className="flex flex-row items-center gap-2">
            <div className="bg-black-90 text-white px-3 py-2 rounded-xl w-fit text-sm mt-3">
              {QuestionTypeLabel[question.answer_type]}
            </div>
            {
              question?.thinking_time ? (
                <div className="border border-main-link px-3 py-2 rounded-xl w-fit text-sm mt-3 flex flex-row items-center gap-2">
                  <TimerThinkingIcon />
                  {secondsToMinutes(question.thinking_time)}
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateQuestion;
