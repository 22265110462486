import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { isTestPOEditorEnabled } from "configs/jobs/constants";
import config from "./config";
import configTest from "./configTest";

const filteredListLanguage = (isTestPOEditorEnabled ? configTest : config).forEach(
  item => item.value
);

const filteredTranslation = (isTestPOEditorEnabled ? configTest : config).reduce((acc, item) => {
  const { value, translation } = item;
  acc[value] = { translation };

  return acc;
}, {});

i18n.use(LanguageDetector).use(initReactI18next).init({
  debug: !["production", "staging"]?.includes(process.env.REACT_APP_ENV),
  fallbackLng: "en",
  whitelist: filteredListLanguage,
  resources: filteredTranslation
},
(err, t) => {
  if (err) {
    console.log("something went wrong loading", err);
  }
  return t("key"); // -> same as i18next.t
});

export default i18n;
