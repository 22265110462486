/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import moment from "moment";
import styled from "styled-components";
import { getFontColorFromBgColor } from "mixins/helperDynamicStyles";
import { getLoggedInParam, getToken } from "store/modules/auth/selectors";
import { store } from "store";
import {
  getAllLanguages,
  getCandidateIdFromRoute,
  getDefaultUserLanguage,
  isCandidateRoute,
  isCurrentUserCandidate,
  selectLanguage
} from "mixins/helperCandidate";
import { useSelector } from "react-redux";

const StyledLabel = styled("span")`
  color: ${props => props.color} !important;
`;

const Language = ({
  isPublic,
  currentLanguage,
  currentLanguagePublic,
  userLanguage,
  currentCandidateLang,
  currentCandidateId,
  updateProfileLanguage,
  updateCandidateLang,
  saveLanguage,
  saveLanguagePublic,
  brandingColor,
  candidateToken
}) => {
  const { i18n } = useTranslation();
  const isLoggedIn = getLoggedInParam(store.getState());
  const isRecruiterLoggedIn = Boolean(getToken(store.getState()));
  const langItems = getAllLanguages();

  const router = useSelector(state => state.router);
  const pathname = router?.location?.pathname || window.location.pathname || "";

  const fontColor = getFontColorFromBgColor({ hex: brandingColor });

  const currentRouteCandidateId = getCandidateIdFromRoute(pathname);

  const handleLanguage = (value, withChangeCurrLang = true) => {
    const selectedLanguage = selectLanguage(value);

    i18n.changeLanguage(selectedLanguage.value);
    moment.locale(selectedLanguage.value);

    if (withChangeCurrLang) saveLanguage(selectedLanguage.value);

    if (isPublic) {
      saveLanguagePublic(selectedLanguage.value);
      if (currentCandidateId === currentRouteCandidateId && Boolean(candidateToken)) {
        updateCandidateLang({
          userId: currentCandidateId,
          language: selectedLanguage.value
        });
      }
      // eslint-disable-next-line max-len
    } else if (isLoggedIn && !isCurrentUserCandidate && isRecruiterLoggedIn && !isCandidateRoute) { updateProfileLanguage(selectedLanguage.value); }
  };

  useEffect(() => {
    const defaultLanguage = getDefaultUserLanguage(currentLanguage);

    // @TODO: Recruiters function
    if ((userLanguage !== defaultLanguage && !isPublic)) {
      handleLanguage(defaultLanguage);
    }
  }, [userLanguage, currentLanguage, isPublic]);

  useEffect(() => {
    // @TODO: Candidates or unauthenticated users function
    if (isPublic) {
      const defaultLanguage = getDefaultUserLanguage(currentLanguagePublic);

      if ((currentCandidateLang && currentCandidateLang !== defaultLanguage) ||
        i18n.language !== defaultLanguage) {
        handleLanguage(currentLanguagePublic, false);
      }
    }
  }, [currentCandidateLang, isPublic, currentLanguagePublic, i18n]);

  useEffect(() => {
    const currLang = isPublic ? currentLanguagePublic : currentLanguage;
    const selectedLanguage = selectLanguage(currLang);
    const root = document.getElementById("root");
    if (i18n.language !== currLang) {
      i18n.changeLanguage(selectedLanguage.value);
      moment.locale(selectedLanguage.value);
    }
    if (root?.classList) {
      if (currLang === "ar") {
        root.classList.add("arabic");
      } else {
        root.classList.remove("arabic");
      }
    }
  }, [currentLanguage, currentLanguagePublic]);

  const currentLanguageLabel = useMemo(
    () =>
      selectLanguage(isPublic ? currentLanguagePublic : currentLanguage).text,
    [currentLanguage, currentLanguagePublic]
  );

  return (
    <Dropdown className="dropdown__language" onSelect={handleLanguage}>
      <Dropdown.Toggle id="dropdown-basic" className="dropdown__language-btn">
        <StyledLabel color={fontColor}>{currentLanguageLabel}</StyledLabel>
      </Dropdown.Toggle>

      <Dropdown.Menu
        align="end"
        className="dropdown__language-menu n-bg-white"
        style={{ right: 0, left: "auto" }}
      >
        <div
          style={{
            position: "relative",
            zIndex: 999,
            backgroundColor: "white"
          }}
        >
          {langItems.map(item => (
            <Dropdown.Item
              key={item.value}
              eventKey={item.value}
              className="dropdown__language-item"
            >
              <div
                style={{
                  background: "white"
                }}
              >
                {item.text}
              </div>
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

Language.defaultProps = {
  isPublic: undefined,
  currentLanguage: undefined,
  currentLanguagePublic: "",
  userLanguage: "",
  currentCandidateLang: "",
  currentCandidateId: "",
  saveLanguage: undefined,
  brandingColor: "",
  candidateToken: undefined
};

Language.propTypes = {
  isPublic: PropTypes.bool,
  currentLanguage: PropTypes.string,
  currentLanguagePublic: PropTypes.string,
  userLanguage: PropTypes.string,
  currentCandidateLang: PropTypes.string,
  currentCandidateId: PropTypes.string,
  updateProfileLanguage: PropTypes.func.isRequired,
  updateCandidateLang: PropTypes.func.isRequired,
  saveLanguage: PropTypes.func,
  saveLanguagePublic: PropTypes.func.isRequired,
  brandingColor: PropTypes.string,
  candidateToken: PropTypes.string
};

export default React.memo(
  Language,
  (prevProps, nextProps) =>
    prevProps.currentLanguage === nextProps.currentLanguage &&
    prevProps.userLanguage === nextProps.userLanguage &&
    prevProps.currentCandidateLang === nextProps.currentCandidateLang &&
    prevProps.currentCandidateId === nextProps.currentCandidateId &&
    prevProps.isPublic === nextProps.isPublic &&
    prevProps.currentLanguagePublic === nextProps.currentLanguagePublic &&
    prevProps.brandingColor === nextProps.brandingColor &&
    prevProps.candidateToken === nextProps.candidateToken
);
