/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { isMobileOnly } from "react-device-detect";

import PageWrapper from "hoc/PageWrapper";
import Header from "components/Common/Header/Main/Onboarding";
import withLoaderScreen from "hoc/withLoaderScreen";

import Welcome from "./Welcome";
import StepLocation from "./StepLocation";
import StepCompany from "./StepCompany";
import StepCompanySize from "./StepCompanySize";
import StepInvite from "./StepInvite";
import Completion from "./Completion";
import { Checked, Circle } from "./svgIcons";
import "./styles.scss";

const Steps = [
  Welcome,
  StepLocation,
  StepCompany,
  StepCompanySize,
  StepInvite,
  Completion
];

const getStep = ({
  is_country_provided: isCountryProvided,
  is_agency_name_provided: isAgencyNameProvided,
  is_organisation_size_provided: isOrganisationSizeProvided,
  is_team_invited: isTeamInvited
}) => {
  if (isTeamInvited) {
    return 5;
  }
  if (isOrganisationSizeProvided) {
    return 4;
  }
  if (isAgencyNameProvided) {
    return 3;
  }
  if (isCountryProvided) {
    return 2;
  }
  return 0;
};

const Onboarding = ({
  agencyKey,
  notifications,
  error,
  isUsersLoading,
  updateAgency,
  addNewUsers,
  clearUserError,
  fetchNotifications,
  setCompletedOnboarding,
  currentUser
}) => {
  const [step, setStep] = useState(0);
  const StepComponent = useMemo(() => Steps[step], [step]);

  useEffect(() => {
    fetchNotifications({ key: agencyKey });
    if (error) {
      clearUserError();
    }
  }, []);

  useEffect(() => {
    if (!notifications?.isLoading && !currentUser?.isLoading) {
      setStep(getStep({ ...notifications, ...currentUser }));
    }
  }, [notifications.isLoading, currentUser]);

  return (
    <PageWrapper header={<Header />}>
      <Container>
        <Row>
          {isMobileOnly ? (
            <div className="onboard__steps-mobile">
              {[...Array(5).keys()].map(item => (step - 1 >= item ? <Checked /> : <Circle />))}
            </div>
          ) : (
            <Col xs={1}>
              <div className="onboard__steps">
                {[...Array(5).keys()].map(item => (step - 1 >= item ? <Checked /> : <Circle />))}
              </div>
            </Col>
          )}
          <Col xs={isMobileOnly ? 12 : 11}>
            <StepComponent
              updateAgency={updateAgency}
              addNewUsers={addNewUsers}
              setStep={setStep}
              error={error}
              isUsersLoading={isUsersLoading}
              country={notifications?.country}
              size={notifications?.size}
              name={notifications?.is_agency_name_provided ? notifications?.name : ""}
              setCompletedOnboarding={setCompletedOnboarding}
            />
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

Onboarding.defaultProps = {
  currentUser: undefined
};

Onboarding.propTypes = {
  notifications: PropTypes.objectOf({
    is_country_provided: PropTypes.bool,
    is_agency_name_provided: PropTypes.bool,
    is_organisation_size_provided: PropTypes.bool,
    is_team_invited: PropTypes.bool
  }).isRequired,
  agencyKey: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  isUsersLoading: PropTypes.bool.isRequired,
  updateAgency: PropTypes.func.isRequired,
  addNewUsers: PropTypes.func.isRequired,
  clearUserError: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  setCompletedOnboarding: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    isLoading: PropTypes.bool
  })
};

export default withLoaderScreen(Onboarding);
