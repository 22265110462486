import { createAction } from "redux-actions";

export const isCandidateLoading = createAction("IS_CANDIDATE_LOADING");

export const isVideoUploading = createAction("IS_VIDEO_UPLOADING");

export const isInfoUpdating = createAction("IS_INFO_UPDATING");

export const fetchCandidateInfo = createAction("FETCH_CANDIDATE_INFO");
export const fetchCandidateInfoResponse = createAction(
  "FETCH_CANDIDATE_INFO_RESPONSE"
);
export const fetchCandidateInfoError = createAction(
  "FETCH_CANDIDATE_INFO_ERROR"
);

export const updateCandidateInfo = createAction("UPDATE_CANDIDATE_INFO");
export const updateCandidateInfoResponse = createAction(
  "UPDATE_CANDIDATE_INFO_RESPONSE"
);
export const updateCandidateInfoError = createAction(
  "UPDATE_CANDIDATE_INFO_ERROR"
);

export const updateCandidateLang = createAction("UPDATE_CANDIDATE_LANG");
export const updateCandidateLangResponse = createAction("UPDATE_CANDIDATE_LANG_RESPONSE");
export const updateCandidateLangError = createAction("UPDATE_CANDIDATE_LANG_ERROR");

export const updateCandidateHistory = createAction("UPDATE_CANDIDATE_HISTORY");
export const updateCandidateHistoryResponse = createAction(
  "UPDATE_CANDIDATE_HISTORY_RESPONSE"
);
export const updateCandidateHistoryError = createAction(
  "UPDATE_CANDIDATE_HISTORY_ERROR"
);

export const fetchCandidateJobInfoResponse = createAction(
  "FETCH_CANDIDATE_JOB_INFO_RESPONSE"
);
export const fetchCandidateJobInfoError = createAction(
  "FETCH_CANDIDATE_JOB_INFO_ERROR"
);
export const setCandidatePosition = createAction("SET_CANDIDATE_POSITION");

export const fetchQuestionsAnswers = createAction("FETCH_QUESTIONS_ANSWERS");
export const fetchQuestionsAnswersResponse = createAction(
  "FETCH_QUESTIONS_ERROR_RESPONSE"
);
export const fetchQuestionsAnswersError = createAction(
  "FETCH_QUESTIONS_ANSWERS_ERROR"
);

export const saveAnswer = createAction("SAVE_ANSWER");
export const saveAnswerResponse = createAction("SAVE_ANSWER_RESPONSE");
export const saveAnswerError = createAction("SAVE_ANSWER_ERROR");

export const retakeAnswer = createAction("RETAKE_ANSWER");
export const retakeAnswerAfterFileUpload = createAction(
  "RETAKE_ANSWER_AFTER_FILE_UPLOAD"
);
export const retakeAnswerResponse = createAction("RETAKE_ANSWER_RESPONSE");
export const videoAttemptResponse = createAction("VIDEO_ATTEMPT_RESPONSE");
export const retakeAnswerError = createAction("RETAKE_ANSWER_ERROR");
export const saveRetakeAnswer = createAction("SAVE_RETAKE_ANSWER");
export const clearRetakeAnswer = createAction("CLEAR_RETAKE_ANSWER");

export const saveIsAllAnswerSaved = createAction("SAVE_IS_ALL_ANSWER_SAVED");

export const saveUserCredentials = createAction("SAVE_USER_CREDENTIALS");

export const saveOnAmazonS3 = createAction("SAVE_ON_AMAZON_S3");
export const saveOnAmazonS3Response = createAction(
  "SAVE_ON_AMAZON_S3_RESPONSE"
);
export const saveOnAmazonS3Error = createAction("SAVE_ON_AMAZON_S3_ERROR");
export const saveBlobOnAmazonS3 = createAction("SAVE_BLOB_ON_AMAZON_S3");

export const saveCurrentQuestion = createAction("SAVE_CURRENT_QUESTION");
export const saveCurrentQuestionIsMuted = createAction("SAVE_CURRENT_QUESTION_MUTED");

export const updateCalendarDates = createAction("UPDATE_CALENDAR_DATES");
export const clearCalendarDates = createAction("CLEAR_CALENDAR_DATES");

export const fetchCandidatesStats = createAction("FETCH_CANDIDATE_STATS");
export const fetchCandidatesStatsResponse = createAction(
  "FETCH_CANDIDATE_STATS_RESPONSE"
);
export const fetchCandidatesStatsError = createAction(
  "FETCH_CANDIDATE_STATS_ERROR"
);

export const savePreviousQuestion = createAction("SAVE_PREVIOUS_QUESTION");
export const clearPreviousQuestion = createAction("CLEAR_PREVIOUS_QUESTION");

export const clearIsVideoUploading = createAction("CLEAR_IS_VIDEO_UPLOADING");
export const clearSavedAnswers = createAction("CLEAR_SAVED_ANSWERS");
export const setSavedAnswers = createAction("SET_SAVED_ANSWERS");
export const clearUploadInfo = createAction("CLEAR_UPLOAD_INFO");
export const clearErrors = createAction("CLEAR_ERRORS");
export const resetCandidateError = createAction("RESET_CANDIDATE_ERROR");

export const clearIsLoading = createAction("CLEAR_IS_LOADING");

export const setCurrentCandidateId = createAction("SET_CURRENT_CANDIDATE_ID");

export const changeCandidateFile = createAction("CHANGE_CANDIDATE_FILE");
export const changeCandidateFileError = createAction("CHANGE_CANDIDATE_FILE_ERROR");

export const updateCandidateFile = createAction("UPDATE_CANDIDATE_FILE");
export const updateCandidateFileResponse = createAction("UPDATE_CANDIDATE_FILE_RESPONSE");
export const updateCandidateFileError = createAction("UPDATE_CANDIDATE_FILE_ERROR");

export const fetchTransferredCandidateInfo = createAction("FETCH_TRANSFERRED_CANDIDATE");

export const processAnswers = createAction("PROCESS_ANSWERS");

export const downloadAll = createAction("DOWNLOAD_ALL");
export const downloadAllError = createAction("DOWNLOAD_ALL_ERROR");
export const downloadAllResponse = createAction("DOWNLOAD_ALL_RESPONSE");

export const updateVideoArchives = createAction("UPDATE_VIDEO_ARCHIVES");
export const updateIsOpenVideoArchives = createAction("UPDATE_IS_OPEN_VIDEO_ARCHIVES");

export const downloadAndDeleteLink = createAction("DOWNLOAD_AND_DELETE_LINK");
export const downloadAndDeleteLinkError = createAction("DOWNLOAD_AND_DELETE_LINK_ERROR");
export const downloadAndDeleteLinkResponse = createAction("DOWNLOAD_AND_DELETE_LINK_RESPONSE");
export const downloadAndDeleteLinkFailed = createAction("DOWNLOAD_AND_DELETE_LINK_FAILED");

export const fetchHintsAndTips = createAction("FETCH_HINTS_AND_TIPS");
export const fetchHintsAndTipsError = createAction("FETCH_HINTS_AND_TIPS_ERROR");
export const fetchHintsAndTipsResponse = createAction("FETCH_HINTS_AND_TIPS_RESPONSE");

export const saveShareCode = createAction("SAVE_SHARE_CODE");
export const saveShareCodeResponse = createAction("SAVE_SHARE_CODE_RESPONSE");
export const saveShareCodeError = createAction("SAVE_SHARE_CODE_ERROR");

export const getIdvCheckData = createAction("GET_IDV_CHECK_DATA");
export const initQuestion = createAction("INIT_QUESTION");
export const getIdvCheckDataLoading = createAction("GET_IDV_CHECK_DATA_LOADING");
export const getIdvCheckDataError = createAction("GET_IDV_CHECK_DATA_ERROR");
export const getIdvCheckDataResponse = createAction("GET_IDV_CHECK_DATA_RESPONSE");

export const updateIdvSkipped = createAction("UPDATE_IDV_SKIPPED");
export const updateIdvSkippedError = createAction("UPDATE_IDV_SKIPPED_ERROR");

export const clearDownloadLinkError = createAction("CLEAR_DOWNLOAD_LINK_ERROR");

export const updateVideoPreference = createAction("UPDATE_VIDEO_PREFERENCE");

export const fetchAllSavedAnswers = createAction("FETCH_ALL_SAVED_ANSWERS");

export const saveDeviceInfo = createAction("SAVE_DEVICE_INFO");

export const setAudioDevice = createAction("SET_AUDIO_DEVICE");
export const setVideoDevice = createAction("SET_VIDEO_DEVICE");
