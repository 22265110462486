/* eslint-disable import/no-cycle */
import { takeEvery, put, call, select } from "redux-saga/effects";

import {
  updateProfileLanguage,
  updateProfileInfo,
  updateProfileInfoResponse,
  fetchProfileInfo,
  fetchProfileInfoPage,
  fetchProfileInfoResponse,
  fetchProfileInfoError,
  isProfileInfoLoading,
  createDevice,
  createDeviceResponse,
  removeDevice,
  confirmDevice,
  confirmDeviceError,
  updateProfileAgencyInfoResponse,
  getCheckoutLinkError,
  fetchUserProfile
} from "store/modules/profile/actions";
import {
  fetchStripePortalUrl,
  getSelectedAgency,
  setCurrentAgency,
  setUpdatedAgency,
  updateAgency,
  updateUsersListResponse
} from "store/modules/users/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";
import { getToken } from "store/modules/auth/selectors";

/* eslint-disable-next-line */
import { setHasCurrentUserLoaded } from "store/modules/common/actions";
import { isEmpty } from "lodash";
import { isCandidateRoute, isCurrentUserCandidate } from "mixins/helperCandidate";
import { fetchCompaniesForTable } from "store/modules/companies/actions";
import { getProfileInfo } from "store/modules/profile/selectors";
import { logErrors } from "mixins/helperLogging";
import useVerificationStore, { VerificationStatus } from "store/verificationStore.ts";
import { getCurrentUserWorker } from "./users";
/* eslint-disable-next-line */
import {
  getRequestWithToken,
  putRequestWithToken,
  postRequestWithToken,
  deleteRequestWithToken,
  patchRequestWithToken
} from "./api";

import {
  getCurrentUser,
  getListOfUsers
} from "../store/modules/users/selectors";

export function* updateCurrentUser({ response }) {
  if (response) yield put(updateProfileInfoResponse(response));
}

export function* updateCurrentUserAgency({ updatedAgency }) {
  const usersList = yield select(getListOfUsers);

  const updatedUsersList = usersList.map(user => {
    if (user?.key === updatedAgency?.key) {
      return {
        ...user,
        ...{ name: updatedAgency?.name }
      };
    }

    return user;
  });

  yield put(updateUsersListResponse(updatedUsersList));
  yield put(setCurrentAgency(updatedAgency));
  yield put(fetchCompaniesForTable({ key: updatedAgency.key }));
  yield put(
    updateProfileAgencyInfoResponse({
      agency: updatedAgency?.key,
      agency_name: updatedAgency?.name
    })
  );
}

export function* updateAgencyWorker({ payload }) {
  try {
    yield put(isProfileInfoLoading(true));

    const token = yield select(getToken);
    const currentUser = yield select(getCurrentUser);
    const response = yield patchRequestWithToken({
      url: `/api/agency/${currentUser.key}/`,
      token,
      payload
    });

    yield call(updateCurrentUserAgency, { updatedAgency: response?.data });
  } catch (error) {
    console.log("[updateAgencyRequestError]", error);
  } finally {
    yield put(isProfileInfoLoading(false));
  }
}

export function* getSelectedAgencyWorker() {
  try {
    yield put(isProfileInfoLoading(true));

    const token = yield select(getToken);
    const currentUser = yield select(getCurrentUser);
    const response = yield getRequestWithToken({
      url: `/api/agency/${currentUser.key}/`,
      token
    });

    yield call(updateCurrentUserAgency, { updatedAgency: response?.data });
  } catch (error) {
    console.log("[updateAgencyRequestError]", error);
  } finally {
    yield put(isProfileInfoLoading(false));
  }
}

export function* getStripePortalUrlWorker({ payload }) {
  try {
    const { verificationStatus } = useVerificationStore.getState();

    if ([
      VerificationStatus.VERIFIED,
      VerificationStatus.VERIFIED_MANUAL,
      VerificationStatus.PENDING_VERIFICATION
    ].includes(verificationStatus)) {
      yield put(setLoadingScreen(true));
      const token = yield select(getToken);
      const currentUser = yield select(getCurrentUser);
      const response = yield getRequestWithToken({
        url: `/api/agency/${currentUser.key}/portal/`,
        token
      });

      if (response?.data?.portal_link) {
        yield put(setUpdatedAgency({ stripe_service_portal_url: response?.data?.portal_link ?? "" }));

        if (payload?.type !== "fetch_stripe_portal_url") {
          let link = `${response?.data?.portal_link}/`;

          if (["upgrade_plan"].includes(payload?.type)) link = `${link}subscriptions/${payload?.primarySubscriptionId}/update/`;

          if (payload?.redirect) { window.location.href = link; } else { window.open(link, "_blank"); }
        }
      }

      yield call(getCheckoutLinkError, {
        payload: "Unable to retrieve your stripe info. Please contact support@willo.video"
      });
    }
  } catch (error) {
    console.error("[getStripePortalUrl]", error);
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* handleProfileInfo() {
  const token = yield select(getToken);

  if (isCurrentUserCandidate || token === null || isEmpty(token) || isCandidateRoute) return false;

  const { status, data } = yield getRequestWithToken({
    url: "/api/user/me/",
    token
  });

  const currentUser = yield select(getCurrentUser);

  if (status === 200 && data && Object.keys(data).length > 0) {
    yield put(fetchProfileInfoResponse(data));
    yield call(getCurrentUserWorker, { payload: { currentUser } });
  }

  return { status, data };
}

export function* fetchUserProfileWorker({ payload }) {
  try {
    yield put(setLoadingScreen(true));
    const token = yield select(getToken);

    const { status, data } = yield getRequestWithToken({
      url: "/api/user/me/",
      token
    });

    if (status === 200 && data && Object.keys(data).length > 0) {
      yield put(fetchProfileInfoResponse(data));

      // eslint-disable-next-line no-unused-expressions
      payload?.successCallback?.();
    }
  } catch (error) {
    logErrors({ error, context: { type: "recruiter" } });
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* fetchProfileInfoWorker() {
  try {
    yield put(getCheckoutLinkError(""));
    yield put(setHasCurrentUserLoaded(true));
    yield put(isProfileInfoLoading(true));
    yield call(handleProfileInfo);
  } catch (error) {
    yield fetchProfileInfoError(error);
    console.log("[fethProfileRequestError]", error);
  } finally {
    yield put(isProfileInfoLoading(false));
  }
}

export function* fetchProfileInfoPageWorker() {
  try {
    yield put(setLoadingScreen(true));
    yield call(fetchProfileInfoWorker);
  } catch (error) {
    yield fetchProfileInfoError(error);
    console.log("[fethProfileRequestError]", error);
  } finally {
    yield put(setLoadingScreen(false));
  }
}

// eslint-disable-next-line consistent-return
export function* updateProfileInfoWorker({ payload = {} }) {
  try {
    yield put(isProfileInfoLoading(true));

    const token = yield select(getToken);

    if (isCurrentUserCandidate || token === null ||
      isEmpty(token) || isCandidateRoute) return false;

    const response = yield patchRequestWithToken({
      url: "/api/user/me/",
      token,
      payload
    });

    yield put(updateProfileInfoResponse(response.data));
    if (payload?.agency_name !== "" || payload?.agency_name) {
      yield call(updateAgencyWorker, {
        payload: { name: payload?.agency_name }
      });
    }
  } catch (error) {
    console.log("[updateProfileRequestError]", error);
  } finally {
    yield put(isProfileInfoLoading(false));

    if (typeof payload?.successCallback === "function") payload.successCallback();
  }
}

export function* updateProfileLanguageWorker({ payload = "en" }) {
  try {
    const profileInfo = yield select(getProfileInfo);
    if (profileInfo?.is_verified) {
      yield put(isProfileInfoLoading(true));

      const token = yield select(getToken);

      if (typeof token !== "undefined" && !isEmpty(token) && !isCandidateRoute) {
        const response = yield patchRequestWithToken({
          url: "/api/user/me/",
          token,
          payload: {
            language: payload
          }
        });

        yield call(updateCurrentUser, { response: response?.data });
      }
    }
  } catch (error) {
    console.log("[updateProfileRequestError]", error);
  } finally {
    yield put(isProfileInfoLoading(false));
  }
}

export function* createDeviceWorker() {
  try {
    yield put(setLoadingScreen(true));

    const token = yield select(getToken);
    const response = yield postRequestWithToken({
      url: "/api/user/mfa/devices/create/",
      token,
      payload: {
        device_name: null
      }
    });

    if (response && (response.status === 200 || response.status === 201)) {
      yield put(createDeviceResponse(response.data));
    }
  } catch (error) {
    console.error("[createDeviceError]", error);
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* confirmDeviceWorker({
  payload: { deviceKey, code, callBack } = {}
}) {
  try {
    yield put(setLoadingScreen(true));

    const token = yield select(getToken);
    const response = yield putRequestWithToken({
      url: `/api/user/mfa/devices/${deviceKey}/confirm/`,
      token,
      payload: {
        otp_token: code
      }
    });

    if (response && (response.status === 200 || response.status === 204)) {
      if (typeof callBack === "function") callBack();
    } else {
      yield put(confirmDeviceError("security.errorCode"));
    }
  } catch (error) {
    console.error("[createDeviceError]", error);
    yield put(confirmDeviceError("security.errorCode"));
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* removeDeviceWorker() {
  try {
    yield put(setLoadingScreen(true));

    const token = yield select(getToken);
    const response = yield deleteRequestWithToken({
      url: "/api/user/mfa/remove/",
      token
    });

    if (response && (response.status === 200 || response.status === 204)) {
      yield call(handleProfileInfo);
    }
  } catch (error) {
    console.error("[removeDeviceError]", error);
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* createInterviewWatcher() {
  yield takeEvery(fetchProfileInfo, fetchProfileInfoWorker);
  yield takeEvery(fetchUserProfile, fetchUserProfileWorker);
  yield takeEvery(fetchProfileInfoPage, fetchProfileInfoPageWorker);
  yield takeEvery(updateProfileInfo, updateProfileInfoWorker);
  yield takeEvery(updateProfileLanguage, updateProfileLanguageWorker);
  yield takeEvery(createDevice, createDeviceWorker);
  yield takeEvery(removeDevice, removeDeviceWorker);
  yield takeEvery(confirmDevice, confirmDeviceWorker);
  yield takeEvery(updateAgency, updateAgencyWorker);
  yield takeEvery(getSelectedAgency, getSelectedAgencyWorker);
  yield takeEvery(fetchStripePortalUrl, getStripePortalUrlWorker);
}

export default createInterviewWatcher;
