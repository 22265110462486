import { connect } from "react-redux";

import { getToken } from "store/modules/auth/selectors";
import { confirmEmail } from "store/modules/auth/actions";
import PrivateRouteAuth from "components/Common/PrivateRouteAuth";
import { getIsAccountLocked } from "store/modules/users/selectors";

const mapStateToProps = state => ({
  isAuthenticated: Boolean(getToken(state)),
  isAccountLocked: getIsAccountLocked(state)
});

const mapDispatchToProps = {
  confirmEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouteAuth);
