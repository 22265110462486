/* eslint-disable import/no-cycle */
import { takeEvery, put, call } from "redux-saga/effects";
import { history } from "store";

import {
  fetchCommonJob,
  fetchCommonJobResponse,
  fetchCommonJobError,
  fetchInviteLink,
  setNewCandidateInfo,
  fetchInviteLinkError,
  isCommonJobsLoading
} from "store/modules/common/actions";
import { fetchCandidateInfo, setCurrentCandidateId } from "store/modules/сandidates/actions";

import { JOB_STATUSES } from "configs/jobs/constants";
import { logErrors } from "mixins/helperLogging";
import { getRequestWithToken, putRequestWithToken } from "./api";

// eslint-disable-next-line consistent-return
export function* fetchInviteLinkWorker({ payload: { jobId = "", withRedirect = true } }) {
  try {
    if (!jobId || jobId === "") {
      yield put(setCurrentCandidateId(""));
      yield call(history.push, "/page-not-found");
      return;
    }

    const response = yield call(putRequestWithToken, {
      url: `/api/job/public/${jobId}/`
    });

    if (response.status === 200) {
      const { invite_link: inviteLink } = response.data;
      const url = new URL(inviteLink);
      const userId = url.pathname.split("/").pop();
      const urlSearchParams = url.searchParams;
      const { access_token: candidateToken } = Object.fromEntries(urlSearchParams.entries());

      yield put(setNewCandidateInfo({ userId, jobId, candidateToken }));
      yield put(fetchCandidateInfo({ userId, jobId, candidateToken }));
      if (withRedirect) yield history.push(`/candidate/tips/${userId}`);
    }
  } catch (error) {
    logErrors({ error, context: { jobId } });

    yield put(fetchInviteLinkError("Could not load info for remote link"));

    if (
      [JOB_STATUSES.CLOSED, JOB_STATUSES.EXPIRED_CLOSED].includes(error?.response?.data?.status) ||
       error?.response?.data?.agency_blocked
    ) {
      yield put(setCurrentCandidateId(""));
      yield call(history.push, "/interview-closed");
      return;
    }

    if ([401, 404]?.includes(error?.response?.status)) {
      yield put(setCurrentCandidateId(""));
      yield call(history.push, "/page-not-found");
    }
  }
}

// eslint-disable-next-line consistent-return
export function* fetchCommonJobWorker({ payload: { jobId = "" } }) {
  try {
    if (!jobId || jobId === "") {
      yield put(setCurrentCandidateId(""));
      return history.push("/page-not-found");
    }

    yield put(isCommonJobsLoading(true));
    yield put(fetchCommonJobResponse({}));
    const response = yield getRequestWithToken({
      url: `/api/job/public/${jobId}/`
    });

    yield put(fetchCommonJobResponse(response.data));

    const { show_hints: showHints = true } = response.data || {};

    if (!showHints) {
      yield fetchInviteLinkWorker({ payload: { jobId, withRedirect: false } });
    }
  } catch (error) {
    logErrors({ error, context: { jobId } });


    yield put(fetchCommonJobError("Could not load job"));
    console.error("Could not load job", error);

    if (
      [JOB_STATUSES.CLOSED, JOB_STATUSES.EXPIRED_CLOSED].includes(error?.response?.data?.status) ||
       error?.response?.data?.agency_blocked
    ) {
      yield put(setCurrentCandidateId(""));
      yield call(history.push, "/interview-closed");
      return null;
    }

    if ([401, 404]?.includes(error?.response?.status)) {
      yield put(setCurrentCandidateId(""));
      yield call(history.push, "/page-not-found");
      return null;
    }
  } finally {
    yield put(isCommonJobsLoading(false));
  }
}

export function* createCommonWatcher() {
  yield takeEvery(fetchCommonJob, fetchCommonJobWorker);
  yield takeEvery(fetchInviteLink, fetchInviteLinkWorker);
}

export default createCommonWatcher;
