/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Breadcrumbs from "components/Breadcrumbs";
import Spinner from "components/Common/Spinner";
import Steps from "components/Steps/Job";
import { DATE_FORMATS } from "configs/dates";
import { CHECKBOXES_TYPE, IDV_CHECK, JOB_STATUSES, MULTIPLE_CHOICE_TYPE } from "configs/jobs/constants";
import Header from "containers/Common/HeaderContainer";
import PreviewContainer from "containers/Jobs/PreviewComponent";
import SetupContainer from "containers/Jobs/SetupComponent";
import TemplatesContainer from "containers/Jobs/TemplatesComponent";
import PageWrapper from "hoc/PageWrapper";
import withLoaderScreen from "hoc/withLoaderScreen";
import languageItems from "i18n/config";
import { jobsBreadcrumbs, jobsEditBreadcrumbs } from "mixins/dynamicRoutes";
import QuestionsContainer from "containers/Jobs/QuestionsContainer/QuestionsContainer";
import ErrorModal from "components/Common/Error/ErrorModal";
import { store } from "store";
import {
  getCurrentActiveStep,
  getCurrentIdvActivationRedirect,
  getIsUpgradeComplete
} from "store/modules/jobs/selectors";
import {
  setIdvActivationRedirect,
  setCurrentStepActive as setCurrentStepActiveState,
  setIsUpgradeComplete
} from "store/modules/jobs/actions";
import { isEmpty } from "lodash";
import {
  getIntegrations,
  getIntegrationsResponse
} from "store/modules/marketplace/actions";
import { connect } from "react-redux";
import { getJobsMaximum } from "store/modules/users/selectors";
import { decodeSurrogatePairs } from "mixins/helpers";

const Create = ({
  t,
  match: {
    params: { id = null }
  },
  currentUser,
  maximumJobs,
  onboarding,
  currentJob: {
    key,
    title = "",
    salary = "",
    is_own_video: isOwn = false,
    video_link: videoLink = "",
    company = null,
    description = "",
    questions = [],
    candidates_cnt: candidatesСnt = 0,
    deadline,
    deadline_timezone,
    reviewers,
    reviewers_to_notify,
    show_availability_calendar,
    show_hints,
    redirect_url,
    default_language: defaultLanguage,
    message_templates,
    status = JOB_STATUSES.DRAFT,
    scorecard
  },
  saveJob,
  librariesDivided,
  fetchCompaniesForQuestion,
  fetchLanguagesForQuestion,
  fetchLibrariesDivided,
  fetchCurrentJob,
  isLoading,
  currentLanguage,
  setModal,
  suggestions,
  fetchSuggestions,
  error
}) => {
  const isUpgradeComplete = getIsUpgradeComplete(store.getState());

  const [editableJobSetup, setEditableJobSetup] = useState({});
  const [editableJobQuestions, setEditableJobQuestions] = useState({});
  const [editableJobTemplates, setEditableJobTemplates] = useState({});
  const [editableJobPreview, setEditableJobPreview] = useState({});

  const [isJobSetupValid, setIsJobSetupValid] = useState(false);
  const [isJobQuestionsValid, setIsJobQuestionsValid] = useState(false);
  const [isJobTemplatesValid, setIsJobTemplatesValid] = useState(false);
  const [currentStepActive, setCurrentStepActive] = useState(() =>
    (isUpgradeComplete === "completed"
      ? getCurrentActiveStep(store.getState())
      : "setup"));

  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSetEditableJobSetup = (updatedInterview, successCallback) => {
    setEditableJobSetup(updatedInterview);

    if (status === JOB_STATUSES.DRAFT) {
      return saveJob({
        id: key,
        agency: currentUser.key,
        isUpdateJob: true,
        title: updatedInterview.title,
        salary: updatedInterview.salary,
        company: updatedInterview.company.value,
        video_link: updatedInterview.isOwn
          ? updatedInterview.ownVideoUrl
          : updatedInterview.video,
        is_own_video: updatedInterview.isOwn,
        description: updatedInterview.description,
        successCallback
      });
    }

    return successCallback();
  };

  const handleSetEditableJobQuestions = updatedInterview => {
    try {
      setEditableJobQuestions(prev => ({
        ...prev,
        questions: updatedInterview.questions
      }));

      if (status === JOB_STATUSES.DRAFT) {
        // eslint-disable-next-line no-shadow
        const { questions } = updatedInterview;

        const newQuestions = questions?.map(question => {
          let { answer_options: options = [] } = question?.type_specific_data || {};

          options = options?.map((opt, index) => ({
            text: opt.text,
            is_correct: opt.is_correct,
            order: index + 1
          }));

          return {
            ...question,
            ...([MULTIPLE_CHOICE_TYPE, CHECKBOXES_TYPE].includes(question.type) && {
              type_specific_data: {
                answer_options: options
              }
            })
          };
        });

        saveJob({
          id: key,
          agency: currentUser.key,
          isUpdateJob: true,
          isQuestion: true,
          questions: newQuestions,
          successCallback: () => {
            setIsJobQuestionsValid(true);
            setCurrentStepActive("templates");
          }
        });
      } else {
        setIsJobQuestionsValid(true);
        setCurrentStepActive("templates");
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const handleSetEditableJobTemplates = updatedInterview => {
    setEditableJobTemplates(updatedInterview);

    if (status === JOB_STATUSES.DRAFT) {
      saveJob({
        id: key,
        agency: currentUser.key,
        isUpdateJob: true,
        default_language: currentLanguage,
        message_templates: {
          invitation_email: !updatedInterview?.invitation_email?.system_default
            ? updatedInterview?.invitation_email?.value
            : null,
          invitation_sms: !updatedInterview?.invitation_sms?.system_default
            ? updatedInterview?.invitation_sms?.value
            : null,
          reminder_email: !updatedInterview?.reminder_email?.system_default
            ? updatedInterview?.reminder_email?.value
            : null,
          reminder_sms: !updatedInterview?.reminder_sms?.system_default
            ? updatedInterview?.reminder_sms?.value
            : null,
          interview_completed_email: !updatedInterview
            ?.success_notification_email?.system_default
            ? updatedInterview?.success_notification_email?.value
            : null
        }
      });
    }
  };

  const getTemplateValue = (template, templateArray, type, trigger) =>
    (template?.key
      ? {
        label: template.title,
        value: template.key,
        content: template.content,
        type,
        trigger
      }
      : (templateArray || []).find(
        ({ system_default: systemDefault }) => systemDefault
      ));

  useEffect(() => {
    fetchCompaniesForQuestion(currentUser.key);
    fetchLanguagesForQuestion();
    fetchCurrentJob(id);
  }, []);

  const getIsIdvEnabled = useCallback(() => {
    const queryParams = `?is_developer_tools=false&search=${IDV_CHECK}`;

    store.dispatch(getIntegrations({ queryParams }));
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      isMounted = false;

      getIsIdvEnabled();
    }

    return () => {
      isMounted = true;
      store.dispatch(getIntegrationsResponse([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maximumJobs <= onboarding?.agency_jobs_amount && !id) {
      window.location.href = "/jobs";
    }
  }, [maximumJobs, onboarding]);

  useEffect(() => {
    if (currentLanguage) {
      fetchLibrariesDivided({ language: currentLanguage });
    }
  }, [currentLanguage]);

  useEffect(() => {
    setEditableJobSetup({
      id,
      title,
      salary,
      video: isOwn ? "" : videoLink,
      ownVideo: isOwn ? videoLink : "",
      ownVideoUrl: undefined,
      isOwn,
      company: company && {
        label: company.name,
        value: company.key
      },
      description: decodeSurrogatePairs(description)
    });

    setEditableJobQuestions({
      id,
      questions
    });

    setEditableJobPreview({
      id: key,
      show_hints,
      deadline: {
        label: deadline
          ? moment(deadline).format(DATE_FORMATS.SPACE_SEPARATOR)
          : "No End Date",
        value: deadline || null
      },
      deadline_timezone: deadline_timezone ? {
        value: deadline_timezone
      } : {
        label: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
        value: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      reviewers,
      reviewers_to_notify,
      show_availability_calendar,
      redirect_url,
      default_language: {
        label: languageItems.find(({ value }) => value === defaultLanguage)
          ?.text,
        value: defaultLanguage
      },
      status,
      scorecard
    });
  }, [key, id]);

  useEffect(() => {
    setEditableJobTemplates({
      id,
      invitation_email: getTemplateValue(
        message_templates?.invitation_email,
        librariesDivided.invitation_email,
        "email",
        "invite"
      ),
      invitation_sms: getTemplateValue(
        message_templates?.invitation_sms,
        librariesDivided.invitation_sms,
        "sms",
        "invite"
      ),
      reminder_email: getTemplateValue(
        message_templates?.reminder_email,
        librariesDivided.reminder_email,
        "email",
        "reminder"
      ),
      reminder_sms: getTemplateValue(
        message_templates?.reminder_sms,
        librariesDivided.reminder_sms,
        "sms",
        "reminder"
      ),
      success_notification_email: getTemplateValue(
        message_templates?.interview_completed_email,
        librariesDivided.success_notification_email,
        "email",
        "success_email"
      )
    });
  }, [librariesDivided, key, id]);

  const currentActiveStep = getCurrentActiveStep(store.getState());
  const idvRedirectUrl = getCurrentIdvActivationRedirect(store.getState());

  useEffect(() => {
    if (currentActiveStep === "questions" && idvRedirectUrl !== null) {
      setIsJobSetupValid(isUpgradeComplete === "completed");
      store.dispatch(setCurrentStepActiveState("setup"));
      store.dispatch(setIdvActivationRedirect(null));
      store.dispatch(setIsUpgradeComplete(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveStep, idvRedirectUrl]);

  return (
    <PageWrapper header={<Header />}>
      <Container>
        <Row justify="center">
          <Col md={12}>
            <Breadcrumbs
              id={id}
              pathList={id ? jobsEditBreadcrumbs(t) : jobsBreadcrumbs(t)}
            />
          </Col>

          <Col md={{ span: 6, offset: 3 }}>
            <Steps
              currentStepActive={currentStepActive}
              isJobSetupValid={isJobSetupValid}
              isJobQuestionsValid={isJobQuestionsValid}
              isJobTemplatesValid={isJobTemplatesValid}
              setCurrentStepActive={setCurrentStepActive}
            />
          </Col>
          {isLoading && <Spinner />}
          {!isLoading && currentStepActive === "setup" && (
            <Col md={12}>
              <SetupContainer
                setIsJobSetupValid={setIsJobSetupValid}
                setupInfo={editableJobSetup}
                setEditableJobSetup={handleSetEditableJobSetup}
                setCurrentStepActive={setCurrentStepActive}
                isEdit={!isEmpty(id) && id !== null}
              />
            </Col>
          )}
          {!isLoading && currentStepActive === "questions" && (
            <Col md={{ span: 8, offset: 2 }}>
              <QuestionsContainer
                questionsInfo={editableJobQuestions}
                setEditableJobQuestions={handleSetEditableJobQuestions}
                setCurrentStepActive={setCurrentStepActive}
                isEditableQuestions={candidatesСnt <= 0}
                setModal={setModal}
                fetchSuggestions={fetchSuggestions}
                suggestions={suggestions}
                error={error}
              />
            </Col>
          )}
          {!isLoading && currentStepActive === "templates" && (
            <Col md={12}>
              <TemplatesContainer
                setIsJobTemplatesValid={setIsJobTemplatesValid}
                templatesInfo={editableJobTemplates}
                setEditableJobTemplates={handleSetEditableJobTemplates}
                setCurrentStepActive={setCurrentStepActive}
                currentLanguage={currentLanguage}
              />
            </Col>
          )}
          {!isLoading && currentStepActive === "preview" && (
            <Col md={{ span: 6, offset: 3 }}>
              <PreviewContainer
                editableJobPreview={editableJobPreview}
                setCurrentStepActive={setCurrentStepActive}
                editableJobSetup={editableJobSetup}
                editableJobQuestions={editableJobQuestions}
                editableJobTemplates={editableJobTemplates}
                isEditing={!!id}
              />
            </Col>
          )}
        </Row>
      </Container>

      <ErrorModal
        show={showErrorModal}
        hide={() => setShowErrorModal(false)}
        t={t}
      />
    </PageWrapper>
  );
};

Create.defaultProps = {
  onboarding: undefined,
  error: undefined,
  deadline_timezone: undefined
};

Create.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  currentUser: PropTypes.shape({
    key: PropTypes.string
  }).isRequired,
  currentJob: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    salary: PropTypes.string,
    is_own_video: PropTypes.bool,
    video_link: PropTypes.string,
    company: PropTypes.shape({}),
    description: PropTypes.string,
    questions: PropTypes.array,
    candidates_cnt: PropTypes.number,
    deadline: PropTypes.string,
    deadline_timezone: PropTypes.string,
    reviewers: PropTypes.array,
    reviewers_to_notify: PropTypes.array,
    show_availability_calendar: PropTypes.bool,
    show_hints: PropTypes.bool,
    redirect_url: PropTypes.string,
    default_language: PropTypes.string,
    message_templates: PropTypes.shape({
      invitation_email: PropTypes.shape({}),
      invitation_sms: PropTypes.shape({}),
      reminder_email: PropTypes.shape({}),
      reminder_sms: PropTypes.shape({}),
      interview_completed_email: PropTypes.shape({})
    }),
    status: PropTypes.string,
    scorecard: PropTypes.shape({})
  }).isRequired,
  librariesDivided: PropTypes.shape({
    invitation_email: PropTypes.array,
    invitation_sms: PropTypes.array,
    reminder_email: PropTypes.array,
    reminder_sms: PropTypes.array,
    success_notification_email: PropTypes.array
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchCompaniesForQuestion: PropTypes.func.isRequired,
  fetchLanguagesForQuestion: PropTypes.func.isRequired,
  fetchLibrariesDivided: PropTypes.func.isRequired,
  fetchCurrentJob: PropTypes.func.isRequired,
  saveJob: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  setModal: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  error: PropTypes.string,
  onboarding: PropTypes.shape({
    agency_jobs_amount: PropTypes.number
  }),
  maximumJobs: PropTypes.number.isRequired,
  deadline_timezone: PropTypes.string
};

const mapStateToProps = state => ({
  onboarding: state.dashboard?.onboarding,
  maximumJobs: getJobsMaximum(state)
});

export default connect(mapStateToProps)(withLoaderScreen(withTranslation()(Create)));
